 const itemTwo=[
    {
        "key": "1",
        "label": (
            <a style={{display:"block",width:"100%",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}} target="" rel="noopener noreferrer" href="#/Biohuaxing/Excavate/">
                物种多组学大数据功能位点挖掘平台开发
            </a>
        )
    },
    {
        "key": "2",
        "label": (
            <a style={{display:"block",width:"100%",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}} target="" rel="noopener noreferrer" href="#/Biohuaxing/Intellgent/">
                基因组选择及智能化精准育种平台开发
            </a>
        )
    }

]
export default itemTwo