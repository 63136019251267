import React from "react";
import "./deveopt.model.css";
function DeveOptIndex() {
  return (
    <div className="deveopt_app">
      <div className="deveopt_title">
        <h1>基因编辑工具开发及优化</h1>
      </div>
     
    </div>
  );
}

export default DeveOptIndex;
