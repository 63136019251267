import React, { useCallback, useEffect, useRef, useState } from "react";
import "./present.model.css";
import { Button, Carousel,Image} from "antd";


import { ReactComponent as LeftIcon } from "./左.svg";
import { ReactComponent as RightIcon } from "./右.svg";
import cpuimg from "../../assets/img/6e5238f63229b368caac045d3f0e320.png";
import { useNavigate } from "react-router-dom";

import linterautreJson from "../../assets/Json/literature";
const contentStyle = {
  width: "100%",
  height: "23.75rem",
  color: "#red",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom:"0.625rem"
};
function PresentIndex() {
  const navto = useNavigate();
 
  const employeeRef = useRef();
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  const employeeFn=()=>{
    if(size.width<=750){
      return <div className="Pemployee_div">
      <p className="Pemployee_title">技术团队</p>
      <div className="Pemp_item">
        {/* <div className="Pemp_list">
          <img src={require('../../assets/img/wcy.png')} width={40+'%'} alt="" />
          <p>王翠颖</p>
          <p>产品总监</p>
          <p style={{textIndent:"1rem"}}>华中农业大学国家重点实验室硕士生，近十年来专注于出生缺陷防控和肿瘤个体化诊治领域的技术研发和转化研究，拥有丰富的耳聋基因筛查、遗传病诊断和液体活检ctDNA动态监测等临床项目经验，具有项目管理专业人士资格和遗传咨询师认证。</p>
        </div> */}
        <div className="Pemp_list">
          <img src={require('../../assets/img/wm.png')} width={40+'%'} alt="" />
          <p>王猛</p>
          <p>研发总监</p>
          <p style={{textIndent:"1rem"}}>云南大学国家重点实验室硕士研究生，主攻家养动物进化和临床医学方向，其中多中心癌症早筛项目经验丰富。拥有十年的大数据生物信息学领域经验，并发表过多篇文章和专利。</p>
        </div>
        <div className="Pemp_list">
          <img src={require('../../assets/img/yy.png')} width={50+'%'} alt="" />
          <p>喻宇烨</p>
          <p>技术总监</p>
          <p style={{textIndent:"1rem"}}>华中农业大学作物遗传育种专业，硕士，近十年专注于基因组学大数据方向，在分子育种和基因组选择育种等生物育种方向经验丰富，发表过一系列相关软著、专利和文章累计达数十篇。</p>
        </div>
        <div className="Pemp_list">
          <img src={require('../../assets/img/yhz.png')} width={40+'%'} alt="" />
          <p>于海至</p>
          <p>交付部总监</p>
          <p style={{textIndent:"1rem"}}>华中农业大学国家重点实验室硕士，拥有十年基因和质谱领域项目经验，其中在实验室管理及项目管理上经验丰富，并搭建一整套成熟的、广受客户认可的基因科技上市企业的实验室管理及运营交付体系。</p>
        </div>
        <div className="Pemp_list">
          <img src={require('../../assets/img/zcy.png')} width={40+'%'} alt="" />
          <p>张春阳</p>
          <p>信息化总监</p>
          <p style={{textIndent:"1rem"}}>毕业于华北理工大学，多年的智慧平台开发经验，精通多种开发语言，专注于校园、医疗机构和药企的智慧管理平台开发。</p>
        </div>
       
      </div>
    </div>
    }else {
      return <div className="employee_div">
      <p className="employee_title">技术团队</p>
      <Carousel
      dots={false}
        ref={employeeRef}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "23.75rem",
          top: "40%",
        }}
     
      >
        <div>
          <div style={contentStyle}>
            {/* <div className="employee_item">
              <img src={require('../../assets/img/wcy.png')}
              style={{
                width:"25%",
                height:"40%"
              }}
              loading="lazy"
              alt="" />
              <p className="employee_name">王翠颖</p>
              <p className="employee_position">产品总监</p>
              <p className="employee_content">华中农业大学国家重点实验室硕士生，近十年来专注于出生缺陷防控和肿瘤个体化诊治领域的技术研发和转化研究，拥有丰富的耳聋基因筛查、遗传病诊断和液体活检ctDNA动态监测等临床项目经验，具有项目管理专业人士资格和遗传咨询师认证。</p>
            </div> */}
            <div className="employee_item">
              <img src={require('../../assets/img/wm.png')}
              style={{
                width:"25%",
                height:"40%"
              }}
              loading="lazy"
              alt="" />
              <p className="employee_name">王猛</p>
              <p className="employee_position">研发总监</p>
              <p className="employee_content">云南大学国家重点实验室硕士研究生，主攻家养动物进化和临床医学方向，其中多中心癌症早筛项目经验丰富。拥有十年的大数据生物信息学领域经验，并发表过多篇文章和专利。</p>
            </div>
            <div className="employee_item">
              <img src={require('../../assets/img/yy.png')}
              style={{
                width:"34%",
                height:"40%"
              }}
              loading="lazy"
              alt="" />
              <p className="employee_name">喻宇烨</p>
              <p className="employee_position">技术总监</p>
              <p className="employee_content">华中农业大学作物遗传育种专业，硕士，近十年专注于基因组学大数据方向，在分子育种和基因组选择育种等生物育种方向经验丰富，发表过一系列相关软著、专利和文章累计达数十篇。</p>
            </div>
            <div className="employee_item">
              <img src={require('../../assets/img/yhz.png')}
              style={{
                width:"25%",
                height:"40%"
              }}
              loading="lazy"
              alt="" />
              <p className="employee_name">于海至</p>
              <p className="employee_position">交付部总监</p>
              <p className="employee_content">华中农业大学国家重点实验室硕士，拥有十年基因和质谱领域项目经验，其中在实验室管理及项目管理上经验丰富，并搭建一整套成熟的、广受客户认可的基因科技上市企业的实验室管理及运营交付体系。</p>
            </div>
          </div>
        </div>
        <div>
          <div style={contentStyle}>
         
            <div className="employee_item">
              <img src={require('../../assets/img/zcy.png')}
              style={{
                width:"25%",
                height:"40%"
              }}
              loading="lazy"
              alt="" />
              <p className="employee_name">张春阳</p>
              <p className="employee_position">信息化总监</p>
              <p className="employee_content">毕业于华北理工大学，多年的智慧平台开发经验，精通多种开发语言，专注于校园、医疗机构和药企的智慧管理平台开发。</p>
            </div>
            <div className="employee_item" style={{background:"#fff"}}></div>
          </div>
        </div>
        {/* <div>
          <div style={contentStyle}>
          <div className="employee_item"></div>
            <div className="employee_item"></div>
            <div className="employee_item"></div>
          </div>
        </div> */}
        {/* <div>
          <div style={contentStyle}>
          <div className="employee_item"></div>
            <div className="employee_item"></div>
            <div className="employee_item"></div>
          </div>
        </div> */}
      </Carousel>
      <Button
        onClick={() => {
          employeeRef.current.prev();
        }}
        className="employee_leftButton"
      >
        <LeftIcon style={{background: "transparent"}} />
      </Button>
      <Button
        onClick={() => {
          employeeRef.current.next();
        }}
        className="employee_rightButton"
      >
        <RightIcon />
      </Button>
    </div>
    }
  }
  return (
    <div className="present_div">
      <div className="present_text_div" style={{height:size.width<=750?"80vh":"60vh"}}>
        <div className="present_text" >
         
          <div className="BigTtile" style={{ fontSize:size.width<=750?"12px":"1.25rem",flexDirection:size.width<=750?"column":"" }}>
            <p style={{width:size.width<=750?"100%":"50%"}}>
            百奥华兴创立于2023年，以基因大数据智算软件与智能平台为核心，专注人类疾病的精准诊断及生物育种研究。团队成员来自顶尖高校，先后在NG、NC、PNAS和MP等学术期刊发表相关文章上百篇。凭借强大的科研能力、自有平台和数据资源，研发智算加速软件和一站式育种平台，致力于开发突破性的疾病诊断软件和颠覆性的育种技术。自始至终，我们坚持自主知识产权的智算技术研发，为人类健康和农业发展提供科技支持。
            </p>
            <img src={require("../../assets/img/firm1.png")} style={{width:size.width<=750?"100%":"40%"}} alt=""  loading="lazy" />
          </div>
        </div>
      </div>
      <div className="high_performance" style={{height:size.width<=750?"80vh":"40vh"}}>
        <div className="high_child">
          <h1>高性能超算中心</h1>
          <div className="high_text" style={{ fontSize:size.width<=750?"12px":"1.25rem",flexDirection:size.width<=750?"column":"" }}>
            <p style={{ fontSize:size.width<=750? "0.75rem":"1.25rem", lineHeight: "2.5rem" }}>
              百奥华兴高性能超算中心（High Performance Supercomputer Center,
              HPSC）采用GPU、Intel
              9代CPU和分布式存储的高效组合，实现极速稳定的基因大数据分析、算法实现及交付。本地和云计算资源高效支撑智能育种运算平台。随着公司业务的发展，高性能超算中心将会持续升级，
              以保证高效的基因大数据处理和安全的数据存储。 13，040个物理核数
              2,526 Tflops 计算峰值速度 80 TB 总内存 10PB 总储存
            </p>
            <img src={cpuimg} width={100 + "%"} height={80 + "%"} alt=""  loading="lazy" />
          </div>
        </div>
      </div>
      <div className="text_div" style={{height:size.width<=750?"120vh":"100vh",marginTop:size.width<=750?"3.125rem":"",marginBottom:size.width<=750?"12.5rem":""}}>
        <h1>文章一览</h1>
        <div className="text_child">
          {linterautreJson.map((v, i) => {
            return (
              <div
              style={{width:size.width<=750?"30%":"24%",marginLeft:size.width<=750?"0.625rem":""}}
                className="literature_item"
                key={v.id}
                onClick={() => {
                  navto(v.path + v.src);
                }}
              >
                <img
                  src={require("../../assets/img/" + v.img + ".png")}
                  alt=""
                  loading="lazy"
                />
                <p>发表期刊:{v.periodical}</p>
                <p>发表时间:{v.time}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="Comfortable_office" style={{height:size.width<=750?"70vh":"",marginTop:size.width<=750?"15.625rem":""}}>
        <div className="Comfortable_office_child">
          <h1 className="offic_title">舒适办公</h1>
          <div className="offic_imgItem">
            <div className="img_top">
            
              <Image
                src={require("../../assets/img/firm3.png")}
                width={size.width<=750?40+"%":17 + "%"}
                style={{
                  
                  marginRight:"0.625rem"
                }}
                alt=""
                loading="lazy"
              />
              <Image
                src={require("../../assets/img/firm8.png")}
                width={size.width<=750?70+"%":30 + "%"}
                style={{
                  margin:"0 0.625rem"
                }}
                alt=""
                loading="lazy"
              />
            </div>
            <div className="img_center">
              <Image
                src={require("../../assets/img/firm2.png")}
                width={size.width<=750?60+"%":40 + "%"}
                style={{
                  marginRight:"0.625rem"
                }}
                alt=""
                loading="lazy"
              />
           <Image
                src={require("../../assets/img/firm7.png")}
                width={size.width<=750?33.5+"%":22.5 + "%"}
                style={{
                  marginLeft:"0.625rem"
                }}
                alt=""
                loading="lazy"
              />
            </div>
            <div className="img_bottom">
             
              
            
            </div>
          </div>
        </div>
      </div>
      <div className="off_tema" style={{marginTop:size.width<=750?"-3.75rem":"",paddingBottom:size.width<=750?"1.25rem":""}}>
        <div className="off_tema_child">
          <h1>公司团队</h1>
          <div className="boss_present" style={{flexDirection:size.width<=750?"column":""}}>
            <div className="left" style={{width:size.width<=750?"100%":""}}>
              <p className="boss_job_position">创始人</p>
              <p className="boss_name">
                <span>梁齐齐</span>
                <span>副研究员</span>
                <span>董事长 ＆ CEO</span>
              </p>
              <p className="boss_content">
              毕业于华中农业大学，北京市生物医药研究专业副研究员，十多年的基因组学大数据研究经验，专注于高通量基因测序大数据的软件和产品开发，以一作或通讯作者在PNAS, MP和GPB等期刊发表10余篇研究论文，申请软著和专利多项。
              </p>
            </div>
            <div className="right">
              <img src={require('../../assets/img/lqq.png')}
              width={size.width<=750?100+"%":35+'%'}
           
              loading="lazy"
              alt="" />
            </div>
          </div>
          {
            employeeFn()
          }
        </div>
      </div>
    </div>
  );
}

export default PresentIndex;
