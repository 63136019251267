const itemOne = [
  {
    key: "1",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        target="_self"
        rel="noopener noreferrer"
        href="#/Biohuaxing/Illm/"
      >
        Illumina/T7 二代测序
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        target=""
        rel="noopener noreferrer"
        href="#/Biohuaxing/pacbio/"
      >
        PacBio/ONT 三代测序
      </a>
    ),
  },
  {
    key: "3",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        target=""
        rel="noopener noreferrer"
        href="#/Biohuaxing/Genomics/"
      >
        T2T基因组测序组装
      </a>
    ),
  },
  {
    key: "4",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        target=""
        href="#/Biohuaxing/Pang"
        rel="noopener noreferrer/"
      >
        泛基因组测序
      </a>
    ),
  },
  {
    key: "5",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        target=""
        rel="noopener noreferrer"
        href="#/Biohuaxing/sequencing/"
      >
        单细胞测序分析
      </a>
    ),
  },
  // {
  //   "key": "6",
  //   "label": (
  //     <a target="" rel="noopener noreferrer" href="/Multi">
  //       多组学大数据功能位点挖掘数据库平台
  //     </a>
  //   )
  // },
  {
    key: "7",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        target=""
        rel="noopener noreferrer"
        href="#/Biohuaxing/analysis/"
      >
        多组学大数据联合分析
      </a>
    ),
  },
  {
    key: "8",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        target=""
        rel="noopener noreferrer"
        href="#/Biohuaxing/Functional/"
      >
        质谱分析
      </a>
    ),
  },
  {
    key: "9",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        target=""
        rel="noopener noreferrer"
        href="#/Biohuaxing/Resources/"
      >
        种质资源评价
      </a>
    ),
  },
  {
    key: "10",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        target=""
        rel="noopener noreferrer"
        href="#/Biohuaxing/Mole/"
      >
        分子身份证开发
      </a>
    ),
  },
  // {
  //   "key": "11",
  //   "label": (
  //     <a target="" rel="noopener noreferrer" href="/species">
  //       物种单倍型数据库开发
  //     </a>
  //   )
  // },
  // {
  //   "key": "12",
  //   "label": (
  //     <a target="" rel="noopener noreferrer" href="/Chip">
  //       基因分型芯片开发
  //     </a>
  //   )
  // },
  {
    key: "13",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        target=""
        rel="noopener noreferrer"
        href="#/Biohuaxing/animals/"
      >
        动植物全基因组重测序
      </a>
    ),
  },
];
export default itemOne;
