import React from "react";
import "./analysis.model.css";
function AnalysisIndex() {
  return (
    <div className="analysis_app">
      <div className="analays_title">
        <h1>多组学大数据联合分析</h1>
      </div>
      <div className="analay_DNA">
        <div className="analay_DNA_child">
          <p className="DNA_title">一、DNA 与转录组联合分析</p>
          <p className="DNA_context">
            <span>
              目前，在动植物分子研究领域中，de
              novo测序和转录组测序的结合是研究最多、使用最广泛的方法。加入转录组数据后，可辅助基因组注释，对分析结果进行支持和补充。
            </span>
            <span>
              全基因组重测序是通过对已有参考序列的物种的不同个体进行基因组测序，并以此为基础进行个体或群体水平的遗传差异性分析，进一步的利用统计方法进行影响表型和经济性状候选基因和功能突变的研究。通过全基因组重测序鉴定到的基因突变，可以对其进行注释，看突变是否真正带来表达层面的变化。但是，基因表达的调控是复杂的，DNA
              层面的突变虽然会导致表达的变化，但表达是否真的发生变化，还是需要通过转录组鉴定才可以得到。
            </span>
          </p>
          <div className="demo_anala">
            <div className="demo_title">
              <p>案例解析</p>
              <p>案例:绵羊驯化及羊尾性状机制研究</p>
              <p>
                <span>发表期刊：Nature Communications </span>
                <span>分析策略：GWAS+转录组表达量</span>
              </p>
              <p>研究结果</p>
              <p>
                该研究收集了248个个体进行高深度的全基因组重测序，同时还收集了四种羊（短瘦尾、短脂尾、长脂尾和肥臀）12个个体的绵羊尾部脂肪组织进行RNA-Seq分析。通过全基因组选择分析和基因组关联分析（GWAS），利用单核苷酸多态性（SNP）和拷贝数变异（CNV）分子标记，挖掘了一系列与驯化、重要表型形状和农业性状相关的候选基因，并检测到一些已经发生了明显的等位基因频率差异分化的重要候选基因区域。
                针对四种不同尾型（长脂尾、肥臀、短脂尾和短瘦尾）性状的绵羊进行两两比较的全基因组选择分析，挖掘出PDGFD这个被共同选择的基因，进一步解析基因结构，发现该基因启动子区域的基因型分布，以及位于该基因的一个非同义突变位点（半胱氨酸/酪氨酸）的等位基因频率在肥尾/肥臀羊和瘦尾羊中差异显著。通过后续的转录组分析以及RT-PCR、qPCR和western
                blot实验验证发现该基因在瘦尾羊中的表达显著高于肥尾羊。通过探索脂肪生成通路，发现PDGFD基因表达的蛋白通过形成二聚体来激活PDGFRβ通路，而该通路在脂肪扩增的过程中起着抑制作用，也进一步证实了PDGFD基因在尾脂形成中起着负调控作用。
              </p>
              <img
                src={require("../../assets/img/图片60.png")}
                width={50 + "%"}
                alt=""
                loading="lazy"
              />
              <p>
                参考文献：Li, X., Yang, J., Shen, M., Xie, X. L., Liu, G. J.,
                Xu, Y. X., Li, M. H. (2020). Whole-genome resequencing of wild
                and domestic sheep identifies genes associated with
                morphological and agronomic traits. Nat Commun, 11(1), 2815.
                doi:10.1038/s41467-020-16485-1
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="gwas_twas">
        <div className="gt_child">
          <p className="gwas_twas_title">二、GWAS 与 TWAS 关联分析</p>
          <p className="gt_context">
            GWAS
            是以全基因组测序或全基因组分析标记为基础，通过基因分型，识别由数百个甚至上千个个体组成的群体中高密度的分子标记，筛选出与复杂性状表现型变异相关联的分子标记，进而挖掘与表型相关的候选基因。但GWAS只是给出和某个性状关联的
            SNP 位置，究竟是哪个基因影响表型，需要我们进一步分析锁定。GWAS
            与TWAS关联分析，是利用GWAS结果，群体材料全基因组转录组结果，候选位点的LD等信息，构建统计模型，给出候选位点每个基因的可能性，利于我们更快的找到关键基因。
          </p>
          <div className="gtdemo_anala">
            <div className="gtdemo_title">
              <p>案例解析</p>
              <p>案例:预测影响水稻升糖指数和质地的遗传区域</p>
              <p>
                <span>发表期刊：Plant Biotechnology Journal </span>
                <span>分析策略：GWAS+TWAS</span>
              </p>
              <p>研究结果</p>
              <p>
                该研究通过对 305
                个籼稻品种进行重测序分析发现富集在基因区域的2,419,731个SNPs。其中，在5号染色体的LOC_Os05g03600基因座的外显子2上发现了一个T->C的同义突变位点，该位点与中到高的血糖指数（GI）有很高的相关性。在6号染色体上发现一个
                ~230kn的区域（GI6.1）含有26个重要的基因，该区域的变异占总的GI相关变异的88.7%。结合连锁不平衡（linkagedisequilibrium，LD）程度计算获得了19个连接块。对GI6.1区域中的转录组关联分析（TWAS）识别出了顺式调控元件以及13个候选GI关联基因。这些SNPs与GI、粘度的关联性突出了GBSSI对软质和硬质稻米由高GI到中GI的消化率的影响。
              </p>
              <img
                src={require("../../assets/img/图片61.png")}
                width={50 + "%"}
                alt=""
                loading="lazy"
              />
              <p>
                图1.
                GI的全基因组关联研究和突出了GBSSI表达的转录组关联研究。a，染色体5和6中与GI显著相关信号；b，GI6.1
                区域；c，转录组关联研究突出了GBSSI表达；d，eQTL分析将GI6.1区域的关联候选基因缩小到13个
              </p>

              <p>
                参考文献：Anacleto R, Badoni S, Parween S, Butardo VM Jr, Misra
                G, Cuevas RP, Kuhlmann M, Trinidad TP, Mallillin AC, Acuin C,
                Bird AR, Morell MK, Sreenivasulu N. Integrating a genome-wide
                association study with a large-scale transcriptome analysis to
                predict genetic regions influencing the glycaemic index and
                texture in rice. Plant Biotechnol J. 2019 Jul;17(7):1261-1275.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="analay_DNA">
        <div className="analay_DNA_child">
          <p className="DNA_title">三、GWAS 与甲基化关联分析</p>
          <p className="DNA_context">
            利用GWAS已经发现了许多复杂疾病或性状相关联的位点。然而，检测到的遗传变异通常只能解释一小部分的遗传力。近期的研究发现GWAS关联到的性状相关位点也可能富集在增强子，远端的增强子也会通过增强子-启动子通过染色质的3D结构对靶基因进行调控。因此，出现了新的分析方法，“E+G”方法，即在做GWAS分析时，同时考虑增强子和基因对性状的影响。类似的，许多遗传变异通过转录调控来影响复杂的性状。DNA
            甲基化也与一些遗传变异相关，称为甲基化数量性状位点（mQTLs）。为了更好的揭示表型的生物学机制，一些研究将遗传变异和甲基化整合在一起和性状进行关联分析。影响基因表达水平的
            DNA
            甲基化位点富集在增强子、启动子和基因编码区（包括外显子和内含子），有研究用“E+G+Methyl”，即将增强子-目标基因图谱、mQTLs
            数据库和GWAS结果整合在一起，发现了以前的方法未检测到的、和性状显著相关的新基因
          </p>
          <div className="demo_anala">
            <div className="demo_title">
              <p>案例解析</p>
              <p>案例:利用GWAS揭示甲基化变异的遗传基础</p>
              <p>
                <span>发表期刊：Cell</span>
                <span>分析策略：GWAS+甲基化</span>
              </p>
              <p>研究结果</p>
              <p>
                本研究对全球范围内1000多份拟南芥样本的转录组和甲基化数据进行了分析，利用GWAS揭示了甲基化变异的遗传基础。通过对TE区域的甲基化分析，检测到几个显著关联的SNP峰，证实了之前报道的CMT2本身对CMT2依赖性mCHH的强烈影响，但这里的峰值SNP更接近基因。另一个值得关注的候选基因是AGO9，其涉及siRNA沉默并且与细胞分化的表观遗传调控有关。有趣的是，尽管相关的SNP不同，AGO9看起来与RdDM和CMT2依赖性mCHH相关，表明二者涉及不同的等位基因。RdDM靶向mCHH的峰值SNP位于编码区200kb以外，而
                CMT2依赖性mCHH峰值SNP位于AGO9下游16kb。然而，多个稀有等位基因可能是这两种关联的原因，在分析中包括稀有SNP后，在附近检测到几个非常显著的关联。
              </p>
              <img
                src={require("../../assets/img/图片62.png")}
                width={50 + "%"}
                alt=""
                loading="lazy"
              />
              <p>
                参考文献：Kawakatsu T, Huang SC, Jupe F et al. Epigenomic
                Diversity in a Global Collection of Arabidopsis thaliana
                Accessions. Cell. 2016 Jul 14; 166(2):492-505.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Methylate_atac">
        <div className="Meth_child">
          <p className="meth_title">四、GWAS+甲基化+ATAC</p>
          <div className="meth_context">
            <p style={{ width: "100%", height: "20%", textIndent: "3rem" }}>
              GWAS是基于群体SNPs、SVs等变异信息及个体性状展开的关联分析，能够告知我们与性状相关性高的基因组区域，帮助我们缩小研究探索的范围，也可以辅助生产育种、疾病筛查；相对于GWAS的大量应用，QTL基因的克隆、遗传互作等生物学问题还有待推进，从而避免实际应用中的盲区。有研究者通过271份导入系评估了这些盲区对水稻分子育种的影响，发现34.3%的材料没有完全符合性状的预期。通过GWAS结果与ATAC-seq、甲基化等多组学数据进行集成，可以进一步探索造成性状差异的生物学机制，这些分析也被称为“后GWAS”研究。ATAC-seq与甲基化分析都能从不同层面解析基因组变异导致的调控机制。例如在鼹鼠科基因组研究中，结合ATAC结果，发现上游增强子由于复制融合，该区段染色体开放程度增强，从而提升了相应基因的表达；玉米群体甲基化研究中发现，野生种和栽培种在受选择基因组区段存在甲基化差异，进一步研究发现该甲基化差异区域是外源片段的插入导致了该区域的甲基化差异，从而提升了下游基因的表达。
            </p>
          </div>
          <div className="demo_meth">
            <p
              style={{
                width: "100%",
                height: "10%",
                display: "flex",
                alignItems: "center",
                textIndent: "3rem",
                fontSize: "18px",
              }}
            >
              案例解析
            </p>
            <p
              style={{
                width: "100%",
                height: "10%",
                display: "flex",
                alignItems: "center",
                textIndent: "3rem",
              }}
            >
              案例：玉米雌雄穗表观层面分析及解析基因间区 QTL 的作用机制
            </p>
            <p
              style={{
                width: "100%",
                height: "10%",
                display: "flex",
                alignItems: "center",
                textIndent: "3rem",
              }}
            >
              <span> 发表期刊：Genome Biology</span>
              <span> 分析策略：GWAS＋HiC TAD＋甲基化＋ATAC</span>
            </p>
          </div>
          <div className="outcome_div">
            <p
              style={{
                width: "100%",
                height: "10%",
                display: "flex",
                alignItems: "center",
                textIndent: "3rem",
                fontSize: "18px",
                marginTop: "20px",
              }}
            >
              研究结果
            </p>
            <p
              style={{
                width: "100%",
                height: "10%",
                display: "flex",
                alignItems: "center",
                textIndent: "4rem",
              }}
            >
              本研究首先基于 ATAC-seq、甲基化及 ChIP
              技术比较玉米雌雄穗染色体开放区域（OCR）及表观差异，发现存在15%~20%
              的组织特异性开放区域，其中雌雄穗差异表达与
              OCR、组蛋白修饰及组织特异性转录因子相关。
            </p>
            <img
              src={require("../../assets/img/图片63.png")}
              width={50 + "%"}
              alt=""
              loading="lazy"
            />
            <p>图 1 ATAC 及表观结果</p>
            <p
              style={{
                width: "100%",
                height: "10%",
                display: "flex",
                alignItems: "center",
                textIndent: "4rem",
                margin: "10px 0",
              }}
            >
              通过构建了玉米雌雄穗高分辨率全基因组三维互作图谱，对鉴定到的差异
              loops 与 OCR 区域进行分析，发现存在许多基因远端 OCR
              与基因交互，包括与分生组织发育相关的 FEA4、TB1
              等，此外还鉴定到一些穗发育相关基因与远端
              OCR的存在交互作用，这提示我们远端调控元件与基因间的交互在雌雄穗发育中扮演重要角色。
            </p>
            <img
              src={require("../../assets/img/图片64.png")}
              width={50 + "%"}
              alt=""
              loading="lazy"
            />
            <p>图2. 显著关联 SNP 与 Hi-C、ATAC 结果关联</p>
            <p
              style={{
                width: "100%",
                height: "10%",
                display: "flex",
                alignItems: "center",
                textIndent: "4rem",
                margin: "10px 0",
              }}
            >
              本研究进一步通过 513
              个自交系玉米个体重测序，获得了200,000个SNP，其中23%位于基因间区，这些SNP富集在低DNA甲基化、强组蛋白修饰的染色质开放区域中，这一结果暗示这些SNP可能与远端调控基因相关；通过文献调研，研究者发现33%的性状显著相关SNP位于基因间区（超过基因上下游3kbp）。有135个SNP与基因形成染色质互作，可能通过loops结构参与靶基因调控，从而影响农艺性状。
            </p>
            <p
              style={{
                width: "100%",
                height: "10%",
                display: "flex",
                textIndent: "",
                margin: "1.25rem",
              }}
            >
              参考文献：Sun Yonghao,Dong Liang,Zhang Ying et al. 3D genome
              architecture coordinates trans and cis regulation of
              differentially expressed ear and tassel genes in maize.[J] .Genome
              Biol, 2020, 21: 143.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnalysisIndex;
