import React from 'react'
import './Protein.model.css'
function ProteinIndex() {
  return (
    <div className='Protein_app'>
      蛋白结构解析
    </div>
  )
}

export default ProteinIndex
