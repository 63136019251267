import React, { useCallback, useEffect, useState } from "react";
import "./Animals.model.css";
function AnimalsIndex() {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <div className="Animals_app">
      <div className="animals_title">
        <h1>动植物全基因组重测序</h1>
      </div>
      <div className="Product_overview" style={{height:size.width<=750?"56vh":"20vh"}}>
        <div className="product_child">
          <p>一、产品概述</p>
          <p>
            动植物全基因组重测序是对已知基因组序列的物种进行DNA测序，并在此基础上完成个体或群体分析。通过序列比对，可以检测到大量变异信息，包括单核苷酸多态性位点（SNP），插入缺失位点（Insertion/Deletion,
            InDel）、结构变异（Structure Variation, SV）位点，拷贝数变异（Copy
            Number Variation,
            CNV）位点等，获得同一物种不同个体的遗传变异图谱。利用全基因组重测序技术有助于快速发现与动植物重要性状相关的遗传变异，应用于分子育种中，缩短育种周期。
          </p>
        </div>
      </div>
      <div className="product_good">
        <div className="good_child">
          <p>二、产品优势</p>
          <div className="good_item" style={{paddingLeft:size.width<=750?"0":"12.5rem"}}>
            <img src={require("../../assets/img/图片66.png")} alt="" loading="lazy" />
            <p style={{textIndent:size.width<=750?"0":"1.7rem",paddingLeft:size.width<=750?"1.25rem":""}}>
              <span>经验丰富、数据准确</span>
              <span>至今完成多个物种的重测序样本，拥有丰富的分析经验</span>
            </p>
          </div>
          <div className="good_item" style={{paddingLeft:size.width<=750?"0":"12.5rem"}}>
            <img src={require("../../assets/img/图片67.png")} alt="" loading="lazy" />
            <p style={{textIndent:size.width<=750?"0":"1.7rem",paddingLeft:size.width<=750?"1.25rem":""}}>
              <span>自主测序平台、成本可控</span>
              <span>
                滚环扩增构建DNB测序文库，PCR-free重测序检测InDel更准确，无index
                hopping之忧，低dup rate无需人为干预{" "}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="product_app" style={{height:size.width<=750?"55vh":"30vh"}}>
        <div className="product_app_child">
          <p>三、产品应用</p>
          <div className="product_app_item" style={{flexWrap:size.width<=750?"wrap":"",justifyContent:size.width<=750?"start":""}}>
            <img src={require("../../assets/img/图片68.png")} style={{margin:size.width<=750?"0.625rem 0.625rem":"0 1.25rem"}} alt="" loading="lazy" />
            <img src={require("../../assets/img/图片69.png")} style={{margin:size.width<=750?"0.625rem 0.625rem":"0 1.25rem"}} alt="" loading="lazy" />
            <img src={require("../../assets/img/图片70.png")} style={{margin:size.width<=750?"0.625rem 0.625rem":"0 1.25rem"}} alt="" loading="lazy" />
            <img src={require("../../assets/img/图片71.png")} style={{margin:size.width<=750?"0.625rem 0.625rem":"0 1.25rem"}} alt=""  loading="lazy"/>
            <img src={require("../../assets/img/图片72.png")} style={{margin:size.width<=750?"0.625rem 0.625rem":"0 1.25rem"}} alt="" loading="lazy" />
          </div>
        </div>
      </div>
      <div className="research_contents" style={{marginTop:size.width<=750?"8.25rem":""}}>
        <div className="research_contents_child">
          <p>四、研究内容</p>
          <img
            src={require("../../assets/img/图片74.png")}
            style={{width:size.width<=750?"100%":"60%"}}
            alt=""
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
}

export default AnimalsIndex;
