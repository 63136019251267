import React, { useCallback, useEffect, useState } from "react";
import "./resources.model.css";
function ResourcesIndex() {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <div className="resources_app">
      <div className="resour_title">
        <h1>种质资源评价</h1>
      </div>
      <div className="resout_context" style={{height:size.width<=750?"100%":"35vh",}}>
        <div className="resout_child">
          <p>
            种质资源，又称遗传资源，指选育农作物新品种的基础材料，包括农作物的栽培种、野生种和濒危稀有品种的繁殖材料，以及利用上述繁殖材料创造的各种遗传资源，是作物遗传改良和相关基础研究的物质基础。我国种质资源数量多，分布广，尤其是农作物种质资源，所以对种质资源的保存、管理和创新非常重要。Frankel和Brown于1984年提出核心种质(core
            collection)的概念，采用一定方法，从保存的种质资源中抽取一个核心子集，以最少的遗传资源样本量最大限度地代表包括地理分布在内的整个群体的遗传多样性，而未列入核心种质的其它资源材料则作为保留样本予以保存。
          </p>
          <p>
            核心种质代表了某一种及其近缘野生种的形态特征、地理分布、基因与基因型的最大范围的遗传多样性。对于促进种质交流、利用以及基因库管理具有重要的学术和实用意义。Core
            Hunter
            II可以从大量的种质资源中抽取多样的、有代表性的、冗余度最小的子集，构建核心种质或微核心种质。根据遗传变异标记（SNP）数据，结合多种评估措施（Modified
            Rogers distance、Shannons Diversity
            Index等）进行加权处理，筛选出的材料具有高多样性、高代表性和高等位基因丰富度。核心种质评估包括观测杂合度（Observed
            heterozygosity）、期望杂合度（Expected
            heterozygosity）、Nei遗传多样性（Nei diversity
            index）、香浓维纳指数（Shanon-Wiener
            index）、多态性信息含量（PIC）、等位基因丰度群体近交系数、连锁不平衡分析（LD）等分析。
          </p>
        </div>
      </div>
      <div className="img_p">
        <div className="img_p_child">
          <img src={require("../../assets/img/图片34.png")} style={{width:size.width<=750?"100%":""}} alt=""  loading="lazy" />
          <p>图 基因型比例分布</p>
        </div>
      </div>
      <div className="three_p">
        <p>
          经过标记进一步筛选，遗传多样性、系统发育树、群体结构、主成分分析等进一步评估，筛选的DNA标记进行材料区分并对每个材料标记构建2D
          barcode。下图中不同的颜色代表不同的基因型。
        </p>
      </div>
      <div className="img_p_two" style={{height:size.width<=750?"34vh":"45vh"}}>
        <div className="img_p_two_child">
          <img src={require("../../assets/img/图片35.png")} style={{width:size.width<=750?"100%":""}} alt="" loading="lazy" />
          <p>注：每行代表筛选的候选位点；每列代表一个样品信息</p>
        </div>
      </div>
      <div className="can_div" style={{height:size.width<=750?"31vh":"10vh"}}>
        <p>
          参考文献：Thachuk C, Crossa J, Franco J, Dreisigacker S, Warburton M,
          Davenport GF. Core Hunter: an algorithm for sampling genetic resources
          based on multiple genetic measures. BMC Bioinformatics. 2009;10:243.
          Published 2009 Aug 6. doi:10.1186/1471-2105-10-243
        </p>
      </div>
    </div>
  );
}

export default ResourcesIndex;
