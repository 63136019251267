import React, { useCallback, useEffect, useState } from 'react'
import "./sequencing.model.css"
function SequencingIndex() {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <div className='Sequencing_app'>
      <div className='sequecing_title'>
        <h1>单细胞测序分析</h1>
      </div>
      <div className='Technical_introduction'>
        <div className='Technical_introduction_child'>
          <p className='techn_title'>一、技术介绍</p>
          <p className='tech_content'>基于液滴技术的10XGenomics单细胞转录组测序(Single-cell RNA-sequencing, scRNA-seq) 凭借其单次过万的细胞通量、周期短及准确性高，成为目前主流的单细胞转录组测序平台。</p>
        </div>
      </div>
      <div className='technical_superiority'>
        <div className='technical_superiority_child'>
          <p className='techn_title'>二、技术优势</p>
          <ul>
            <li>细胞通量高：能同时制备1至128个样本，或多达100万个细胞，每个样本细胞数可达80,000个；</li>
            <li>项目周期短：6min实现对上万个细胞的封装，1天内即可完成细胞悬液制备、单细胞捕获、扩增及建库，且可同时获得上万个细胞的分析结果，大大缩短项目周期；</li>
            <li>捕获效率高：单个细胞捕获效率高达65%，高效捕获每个细胞中的表达基因；</li>
            <li>真正意义单个细胞：单个液滴捕获到多个细胞的概率极低0.9%/1000 cells，分析上通过严格质控，利用Barcode 信息及UMI 的文库设计，实现真正意义上的单细胞测序；</li>
            <li>应用范围广：适用于动物、植物新鲜细胞及冻存样品。</li>
          </ul>
        </div>
      </div>
      <div className='Platform_characteristics'>
        <div className='Platform_characteristics_child'>
          <p className='techn_title'>三、平台特点</p>
          <img src={require('../../assets/img/图片79.png')} style={{width:size.width<=750?"100%":"80%"}} alt="" loading="lazy" />
        </div>
      </div>
      <div className='fundamental_principle'>
        <div className='fundamental_principle_child'>
          <p className='techn_title'>四、基本原理</p>
          <ol>
            <li>将准备好的单细胞悬液通过全自动的仪器Chromium Controller快速进行单细胞GEMs（Gene Expression Microarrays）的生成；</li>
            <li>每个GEM未破裂，在GEMs内部进行反转录生成cDNA, 这样每个GEM里的反转录得到的cDNA都带有该细胞的细胞标签Barcode；</li>
            <li>GEMs破裂，所有细胞的cDNA pooling在一起，PCR扩增cDNA全长，使扩增产物量达到建库要求；</li>
            <li>构建带有10x标签的cDNA文库，并在Illumina测序仪上进行短读长NGS测序；</li>
            <li>得到的数据经Cell Ranger软件处理后，可以获得单个细胞水平的基因表达谱和差异分析情况。</li>
          </ol>
        </div>
      </div>
      <div className='preparation_quality'>
        <div className='preparation_quality_child'>
          <p className='techn_title'>五、细胞样本制备与质检</p>
          <p className='pre_content'>将样本制备成单细胞悬液，要求细胞大小均一，细胞团及碎片＜5%。使用Countess® II Automated Cell Counter进行细胞计数和细胞活率测定，要求细胞活率≥85%，细胞浓度及总量≥1×106cell/mL。</p>
        </div>
      </div>
      <div className='inspection_sequencing'>
        <div className='inspection_sequencing_child'>
          <p className='techn_title'>六、文库质检与测序</p>
          <p className='ins_content'>文库构建完成后，先使用Qubit 2.0进行初步定量。随后使用Agilent 2100对文库的Insert DNA大小进行检测。Insert DNA大小符合预期后，使用qPCR方法对文库的有效浓度（2 nM）进行准确定量，以保证文库质量。库检合格后，使用Illumina NovaSeq 6000平台进行PE150测序。其中，Read1包括的Barcode和UMI序列，Read2是所测的转录本序列。</p>
        </div>
      </div>
      <div className='document_div'>
        <div className='document_div_child'>
          <p className='techn_title'>七、文献</p>
          <p className='document_p_one'>浏览使用10x Genomics产品的6,100多篇同行评审文献。（<a href='https://www.10xgenomics.com/cn/publications' target='_blank'>https://www.10xgenomics.com/cn/publications</a>）</p>
          <img src={require('../../assets/img/图片80.png')} width={40 + '%'} alt="" loading="lazy" />
          <p className='docu_content'>
          张献龙院士领衔的棉花团队在Genome Biology杂志上发表了题为“Single-cell resolution analysis reveals the preparation for reprogramming the fate of the plant stem cell niche in lateral meristems”的研究论文，该文用Jin668和不可再生材料TM-1为实验对象，结合单细胞测序技术在单细胞层面解析棉花的体细胞胚胎发生机制。通过对不同再生能力材料的对比，挖掘到决定体细胞胚胎发生能力的关键基因并构建了棉花体细胞胚胎发生的基因调控网络。体细胞经过脱分化形成愈伤组织是体细胞胚胎发生的关键步骤，棉花下胚轴对于激素诱导的响应十分迅速，在诱导3天后下胚轴两端膨大，诱导7天后部分细胞已经完成脱分化产生愈伤组织。因此，选取在培养基上诱导0，1，6，12h的Jin668和TM-1的下胚轴进行单细胞取样，根据marker基因的表达情况最终确定了7种主要的细胞类型。拟时序分析结果发现，Jin668和TM-1的细胞有不同的生长素分布模式，和TM-1相比，Jin668初生木质部细胞有明显的生长素极性建立，更容易脱分化形成愈伤组织。同样的，RNA速率结果表明，TM-1的初生木质部细胞更倾向于分化为根细胞类型而不是形成愈伤组织。综合以上结果表明，在棉花下胚轴体细胞胚胎发生过程中，初生维管组织细胞是形成愈伤组织的主要细胞类型，生长素是影响细胞去分化的主要因素，生长素的极性转运决定了细胞获得胚胎的能力。此外，不同细胞类型之间的细胞通讯也影响细胞的去分化。在这个过程中，一些决定性的基因影响愈伤组织的诱导和体细胞胚胎的形成。最后，通过敲除和过表达实验验证了生长素转运相关基因LAX2影响愈伤组织诱导并能提高胚性愈伤的形成。
          </p>
          <img src={require('../../assets/img/图片81.png')} width={50+'%'} alt="" loading="lazy" />
          <p className='img_p'>
          图 棉花下胚轴单细胞细胞类型注释
          </p>
          <p className='refer_to'>参考文献：Zhu X, Xu Z, Wang G, et al. Single-cell resolution analysis reveals the preparation for reprogramming the fate of stem cell niche in cotton lateral meristem [published correction appears in Genome Biol. 2023 Sep 18;24(1):210]. Genome Biol. 2023;24(1):194. Published 2023 Aug 25. doi:10.1186/s13059-023-03032-6</p>
        </div>
      </div>
    </div>
  )
}

export default SequencingIndex
