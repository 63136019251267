import React, { useCallback, useEffect, useState } from "react";
import "./IlluminaTwo.model.css";
import { useLocation } from "react-router-dom";



function IlluminaTwoIndex() {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  const ulStyle={
    flexDirection:size.width<=750?"column":"",
    paddingBottom:size.width<=750?"3.75rem":""
  }
  const liStyle={
    width:size.width<=750?"100%":"",
    height:size.width<=750?"30%":"",
   
    
  }
  const contextStyle={
   
  }
  return (
    <div className="IlluminaTwo_app">
      <div className="title_div">
        <h1>二代建库测序服务</h1>
      </div>
      <div className="Brief_div">
        <div className="Brief_child">
          <div className="Brief_title">
            <h3>一、简介</h3>
            <p>
              建库测序产品是对合作客户提供的合格样品进行提取建库并可选illumina或者DNBSEQ-T7测序平台测序，或者对合作客户提供的合格文库直接进行测序，产出高质量的测序数据提供给客户进行生物信息学分析。
            </p>
          </div>
          <div className="Brief_img">
            <img src={require("./img/图片1.png")} alt="" loading="lazy" />
            <img src={require("./img/图片2.png")} alt=""  loading="lazy"/>
          </div>
        </div>
      </div>
      <div className="Service_Content">
        <div className="Service_child">
          <h3>二、服务内容</h3>
          <div className="service_table">
            <p>2.1 建库类型</p>
            <table className="table">
              <thead>
                <tr>
                  <td>DNA文库</td>
                  <td>RNA文库</td>
                  <td>PCR-free文库</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>350bp小片段文库</p>
                    <p>全基因组DNA甲基化文库</p>
                    <p>ChIP-seq文库</p>
                    <p>宏基因组文库</p>
                    <p>单细胞基因组文库</p>
                    <p>人外显子文库</p>
                    <p>人重测序文库</p>
                  </td>
                  <td>
                    <p>普通转录组</p>
                    <p>链特异性转录文库</p>
                    <p>IncRNA文库</p>
                    <p>sRNA文库</p>
                    <p>宏转录组文库</p>
                  </td>
                  <td>
                    <p>扩增子文库</p>
                    <p>PCR产物文库</p>
                    <p>其他PCR-free文库</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="service_table">
            <p>2.2 测序类型</p>
            <table className="table_two">
              <thead>
                <tr>
                  <td>测序平台</td>
                  <td>测序策略</td>
                  <td>产出</td>
                  <td>Q20</td>
                  <td>Q30</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Novaseq 6000</td>
                  <td>
                    <p>S4 试剂：</p>
                    <p>PE50/PE150/PE250/SE50</p>
                  </td>
                  <td>
                    <p>S4 试剂：</p>
                    <p>（PE250）：800M flowcell</p>
                    <p>（PE150）: 3.3T flowcell</p>
                    <p>（PE50） ：800M flowcell</p>
                    <p>（SE50） ：800M flowcell</p>
                  </td>
                  <td>85%</td>
                  <td>80%</td>
                </tr>
                <tr>
                  <td>DNBSEQ-T7</td>
                  <td>
                    <p>PE100</p>
                    <p>PE150</p>
                  </td>
                  <td>
                    <p>1-4TB</p>
                    <p>1.5-6TB</p>
                  </td>
                  <td>85%</td>
                  <td>80%</td>
                </tr>
                <tr>
                  <td>Illumina X plus</td>
                  <td>PE150</td>
                  <td>
                    <p>（1.5）500GB data</p>
                    <p>（10TB）3TB data (单 lane>400GB)</p>
                    <p>（25B）8TB data(单 lane>1TB)</p>
                  </td>
                  <td>85%</td>
                  <td>80%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="client_new">
            <p>2.3 客户自建库新产品</p>
            <p
              style={{
                width: "100%",
                height: "10%",
                display: "flex",
                alignItems: "center",
                margin: "0.625rem 0",
                textIndent: "5rem",
              }}
            >
              为满足客户对于项目周期的要求，我们推出客户自建库极致周期产品。
            </p>
            <table className="client_table">
              <thead>
                <tr>
                  <td>产品类型</td>
                  <td>送样地点</td>
                  <td>测序周期</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Illumina/DNBSEQ-T7 PE150 自建库包lane</td>
                  <td>北京</td>
                  <td>4d</td>
                </tr>
                <tr>
                  <td>Illumina/DNBSEQ-T7 PE150 自建库散样</td>
                  <td>北京</td>
                  <td>6d</td>
                </tr>
                <tr>
                  <td>Illumina PE250 自建库散样</td>
                  <td>北京</td>
                  <td>10d</td>
                </tr>
              </tbody>
            </table>
            <ol className="client_list">
              <li>
                周期计算方式：从客户文库到达公司起到数据下机；若客户文库上午
                10：00 后到达，从到样第二天开始计算周期；一次送样超过 2 张
                flowcell 时，每增加 2 张，周期顺延 24h。
              </li>
              <li>送样形式：PE150 自建库散样：最低送样摩尔浓度 2nM。</li>
              <li>
                项目的交付形式
                <p style={{ textIndent: "2rem" }}>
                  文库库检qPCR合格默认上机，不再沟通反馈确认，根据客户index提供拆分后的Fastq格式raw
                  data，云/硬盘交付
                </p>
              </li>
              <li>详细情况：请咨询客户经理。</li>
            </ol>
          </div>
          <div className="new_products">
            <p>2.4 客户建库测序产品</p>
            <p
              style={{
                width: "100%",
                height: "10%",
                display: "flex",
                alignItems: "center",
                margin: "0.625rem 0",
                textIndent: "5rem",
                fontWeight: "normal",
              }}
            >
              为满足科研工作者对于项目周期的需求，我们推出客户建库测序新产品。
            </p>
            <table className="products_table">
              <thead>
                <tr>
                  <td>产品类型</td>
                  <td>送样地点</td>
                  <td>测序周期</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>WGS</td>
                  <td>北京</td>
                  <td>6d</td>
                </tr>
                <tr>
                  <td>真核普通转录组文字</td>
                  <td>北京</td>
                  <td>8d</td>
                </tr>
                <tr>
                  <td>扩增子</td>
                  <td>北京</td>
                  <td>9d</td>
                </tr>
              </tbody>
            </table>
            <ol className="products_list">
              <li>
                测序平台:
                <p style={{ textIndent: "2rem" }}>
                  真核普通转录组/WGS使用Illumina/DNBSEQ-T7 PE150平台测序；
                </p>
                <p style={{ textIndent: "2rem" }}>
                  扩增子使用Illumina PE250平台测序；
                </p>
              </li>
              <li>周期计算方式：从样本到达至数据交付 </li>
              <li>送样形式：组织/核酸</li>
              <li>
                数据产出及质量承诺：数据产出≥合同数据量；Q2 ≥ 85%，Q30 ≥ 80%
              </li>
              <li>
                项目执行及交付形式：
                <p style={{ textIndent: "2rem" }}>
                  Fastq格式的Raw data和clean data进行云/硬盘交付。
                </p>
              </li>
              <li>详细情况：请咨询客户经理。</li>
            </ol>
          </div>
          <div className="Sample_requirements">
            <p>2.4 样品需求</p>
            <table className="table">
              <thead>
                <tr>
                  <td>DNA</td>
                  <td>样品总量（单次建库）</td>
                  <td>RNA</td>
                  <td>样品总量（单次建库）</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>小片段文库</p>
                    <p>（180-350bp）/人重测序文库</p>
                  </td>
                  <td>
                    <p>①人/动 植物/微生物基因组:m≥0.2ug</p>
                    <p>②线粒体、叶绿体、质粒:m≥0.8ug</p>
                    <p>③ ＜500bp PCR 产物:m≥1ug</p>
                    <p>④≥500bpPCR 产物:m≥1ug</p>
                  </td>
                  <td>普通转录组</td>
                  <td>m≥0.8ug</td>
                </tr>
                <tr>
                  <td>全基因组DNA甲基化文库</td>
                  <td>m≥3ug</td>
                  <td>链特异性转录组文库</td>
                  <td>m≥1ug</td>
                </tr>
                <tr>
                  <td>Chip-seq文库</td>
                  <td>m≥3ug</td>
                  <td>原核链特异性文库</td>
                  <td>m≥3ug</td>
                </tr>
                <tr>
                  <td>宏基因组文库</td>
                  <td>m≥0.8ug</td>
                  <td>sRNA文库</td>
                  <td>m≥3ug</td>
                </tr>
                <tr>
                  <td>人外显子文库</td>
                  <td>
                    <p>基因组 DNA：m≥0.4ug</p>
                    <p>FFPE：m≥0.8ug</p>
                    <p>cfDNA/ctDNA：≥0.03ug</p>
                  </td>
                  <td>LncRNA文库</td>
                  <td>m≥2ug</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>宏转录组文库</td>
                  <td>m≥2.5ug</td>
                </tr>
              </tbody>
            </table>
            <table className="table" style={{marginTop:"0.3125rem"}}  >
              <thead>
                <tr>
                  <td style={{textAlign:"center"}} colSpan={2}>PCRfree文库</td>
                
                  <td  style={{textAlign:"center"}}  colSpan={2}>自建库</td>
                  <td></td>
                </tr>
              </thead>
            <tbody>
              <tr>
               <td>PCR产物（扩增子）</td>
               <td>
                <p>混样建库：m≥0.1ug</p>
                <p>单建库m：≥1.5ug</p>
               </td>
               <td colSpan={2}>文件库要求</td>
               {/* <td></td> */}
              </tr>
              <tr>
                <td>基因组PCR-free文库</td>
                <td>m≥5ug</td>
                <td>
                  <p>Qubit浓度：c≥0.5ng/ul</p>
                  <p>Qpcr浓度：c≥3nM</p>
                </td>
                <td>
                  <p>v≥10ul</p>
                  <p>（测序数据量大于30G时，v≥20ul）</p>
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="advantage_div" style={{height:size.width<=750?"100vh":""}}>
        <div className="advantage_child" style={{width:size.width<=750?"100%":""}}>
          <h3>我们的优势</h3>
          <ul style={ulStyle}>
            <li style={liStyle}>
              <p style={{fontWeight:"bold",width:"100%",textAlign:"center",margin:"0.625rem 0"}}>丰富的测序资源</p>
              <p style={contextStyle}>
                目前，公司拥有稳定的Illumina Novaseq、DNBSEQ-T7、PacBio sequel
                IIe和Nanopore PromethION
                48测序合作平台，不仅为合作伙伴提供了多样化的选择，同时也保证了项目的快速运行。
              </p>
            </li>
            <li style={liStyle}>
              <p style={{fontWeight:"bold",width:"100%",textAlign:"center",margin:"0.625rem 0"}}>高质量的数据产出</p>
              <p style={contextStyle}>
                基于公司技术专家们多年的测序平台经验，我们承诺的测序数据指标均高于官方推荐标准，可在测序服务合同中体现。
              </p>
            </li>
            <li style={liStyle}>
              <p style={{fontWeight:"bold",width:"100%",textAlign:"center",margin:"0.625rem 0"}}>高稳定的数据产出</p>
              <p style={contextStyle}>
                对于合格的客户自建库文库包lane/flow
                cell/run的项目，我们不仅承诺lane/flow
                cell/run的总数据量，还可承诺其中每个文库的数据量不低于目标数据量的90%（T7平台除外）。
              </p>
            </li>
            <li style={liStyle}>
              <p style={{fontWeight:"bold",width:"100%",textAlign:"center",margin:"0.625rem 0"}}>严格的质控标准和报告</p>
              <p style={contextStyle}>
                为保证数据的可靠性，我们从样品检测、建库到文库库检均采用严格的质控标准。在项目进行的过程中，样品检测报告和文库库检报告（仅对于客户自建库）均会及时发送，以便合作伙伴随时了解项目进度和质量。数据下机后，我们会进行数据质量评估，并在结题报告中展示总产量以及单个样本产量、有效数据率、测序质量、测序错误率、GC含量等全面的数据产量和质量信息。
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default IlluminaTwoIndex;
