import React, { useEffect, useState } from 'react'
import { Collapse } from "antd-mobile";
import { ReactComponent as ListIcon } from "./列表.svg";


function Mobile(props) {
    const {ItemsOne,ItemsTwo,ItemsThree,ItemsFour,ItemsFive}=props
const [BlockNone,setBlockNone]=useState(false)
  return (
    <div className="Mobile_div" >
          <ListIcon width={25} onClick={(e)=>{
            setBlockNone(true)
            if(BlockNone==true){
            setBlockNone(false)
            }
          }} />
          <div className="List_Item" style={{display:BlockNone?"block":"none"}}>
            <Collapse accordion onClick={()=>{
              setBlockNone(false)
            }}>
              <Collapse.Panel style={{fontSize:"0.875rem"}} key="1" title="科技服务产品">
                {
                    ItemsOne.map((v,i)=>{
                        return <p key={v.key} style={{width:"12.5rem",margin:"10px 0",display:"flex",alignItems:"center",}}>
                            {v.label}
                        </p>
                    })
                }
              </Collapse.Panel>
              <Collapse.Panel style={{fontSize:"0.875rem",overflow:"hidden"}} key="2" title="生物育种大数据平台开发">
              {
                    ItemsTwo.map((v,i)=>{
                        return <p key={v.key} style={{width:"12.5rem",margin:"10px 0",display:"flex",alignItems:"center",}}>
                            {v.label}
                        </p>
                    })
                }
              </Collapse.Panel>
              <Collapse.Panel style={{fontSize:"0.875rem"}} key="3" title="药物研发">
              {
                    ItemsThree.map((v,i)=>{
                        return <p key={v.key} style={{width:"12.5rem",margin:"10px 0",display:"flex",alignItems:"center",}}>
                            {v.label}
                        </p>
                    })
                }
              </Collapse.Panel>
              <Collapse.Panel style={{fontSize:"0.875rem"}} key="4" title="生命科学基础设施平台">
              {
                    ItemsFour.map((v,i)=>{
                        return <p key={v.key} style={{width:"12.5rem",margin:"10px 0",display:"flex",alignItems:"center",}}>
                            {v.label}
                        </p>
                    })
                }
              </Collapse.Panel>
              <Collapse.Panel style={{fontSize:"0.875rem"}} key="5" title="关于百奥华兴">
              {
                    ItemsFive.map((v,i)=>{
                        return <p key={v.key} style={{width:"12.5rem",margin:"10px 0",display:"flex",alignItems:"center",}}>
                            {v.label}
                        </p>
                    })
                }
              </Collapse.Panel>
              <Collapse.Panel  style={{fontSize:"0.875rem"}} key="6" title="联系我们">
                
              </Collapse.Panel>
            </Collapse>
          </div>
        </div>
  )
}

export default Mobile
