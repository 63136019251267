import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import "./Home.model.css";


import { Carousel, Button } from "antd";
import { Space, Swiper, Toast } from 'antd-mobile'


import { useNavigate } from "react-router-dom";

import { ReactComponent as LeftCooper } from "./left.svg";
import { ReactComponent as RightCooper } from "./right.svg";
import newList from "../../assets/Json/newscenter";

const contentStyle = {
  width: "100%",
  height: "31.25rem",
  color: "#fff",
  textAlign: "center",
  background: "#364d79",
  zIndex: "-1",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  position: "relative",
};
const contentStyletwo = {
  width: "100%",
  height: "18.4375rem",
  color: "#fff",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
function HomeIndex() {

  const swiperRef = useRef();
  const cooper = useRef();
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const cooperBottom = () => {
    if (size.width <= 750) {
      return <div className="cooper_bottom">
        <Swiper>
          <Swiper.Item>
            <div className="cooper_item" style={{ width: size.width <= 750 ? "100%" : "", height: size.width <= 750 ? "180px" : "", marginLeft: size.width <= 750 ? "0" : "" }}>
              <img
                src={require("../../assets/img/cooperOne.png")}
                width={40 + "%"}
                height={80 + "%"}
                alt=""
                loading="lazy"
              />
              <div className="cooper_item_right">
                <p style={{ textAlign: "left" }}> <span style={{ fontWeight: "bold" }}>题目：</span> 首个梅花鹿高质量基因组揭示梅花鹿高单宁饮食的适应机制</p>
                <p style={{ fontSize: "0.75rem", textAlign: "left" }}>
                  <span style={{ fontWeight: "bold" }}>发表期刊：</span>Genomics, Proteomics & Bioinformatics(GPB)
                </p>
                <p style={{ fontSize: "0.75rem", textAlign: "left" }}> <span style={{ fontWeight: "bold" }}>发表时间：</span> 2023年2月</p>
                <p style={{ fontSize: "0.75rem", textAlign: "left", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  <span style={{ fontWeight: "bold" }}>研究结果：</span>
                  (1).组装得到目前已知最高质量的梅花鹿全基因组序列，序列总长约2.5 Gb，染色体水平基因组scaffold N50达到78.8 Mb。
                  (2).梅花鹿群体的地理分布与栎树的生长分布具有高度的一致性，并且梅花鹿对高单宁含量饲料(以栎树叶为主)的耐受性较高。
                  (3).UGT基因家族特别是UGT2B基因在梅花鹿基因组中显著扩张。
                  (4).UGT基因家族在梅花鹿肝脏中呈显著的高表达，阐述了UGT基因家族参与梅花鹿单宁代谢的可能分子机制。
                </p>
              </div>
            </div>
          </Swiper.Item>
          <Swiper.Item>
            <div className="cooper_item" style={{ width: size.width <= 750 ? "100%" : "", height: size.width <= 750 ? "180px" : "", marginLeft: size.width <= 750 ? "1%" : "" }}>
              <img
                src={require("../../assets/img/cooperTwo.png")}
                width={40 + "%"}
                height={40 + "%"}
                alt=""
                loading="lazy"
              />
              <div className="cooper_item_right">
                <p style={{ textAlign: "left" }}> <span style={{ fontWeight: "bold" }}>题目：</span> 基因组学分析鉴定马铃薯重要农艺性状基因的分子标记</p>
                <p style={{ fontSize: "0.75rem", textAlign: "left" }}>
                  <span style={{ fontWeight: "bold" }}>发表期刊：</span>Molecular Plant
                </p>
                <p style={{ fontSize: "0.75rem", textAlign: "left" }}> <span style={{ fontWeight: "bold" }}>发表时间：</span> 2018年2月</p>
                <p className="cooper_text" style={{ fontSize: "0.75rem", textAlign: "left", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  <span style={{ fontWeight: "bold" }}>研究结果：</span>
                  该研究对201份马铃薯种质资源材料进行二代测序分析，结果表明（1）马铃薯野生群体具有很高的遗传多样性，尤其是抗病基因；（2）南美马铃薯地方栽培种是单系起源于秘鲁南部，鉴定出529个野生祖先在驯化过程丢失的与环境适应性相关的基因；（3）马铃薯野生群体被分为四个高度分化的亚群，该研究鉴定出几千个高度分化基因和609个驯化基因，包括与马铃薯苦味和块茎形成和发育相关的基因。以上为马铃薯重要农艺性状基因的鉴定及进一步分子育种提供了重要基础。
                </p>
              </div>
            </div>
          </Swiper.Item>
          <Swiper.Item>
            <div className="cooper_item" style={{ width: size.width <= 750 ? "100%" : "", height: size.width <= 750 ? "180px" : "", marginLeft: size.width <= 750 ? "0" : "" }}>
              <img
                src={require("../../assets/img/cooperThree.png")}
                width={40 + "%"}
                height={80 + "%"}
                alt=""
                loading="lazy"
              />
              <div className="cooper_item_right">
                <p style={{ textAlign: "left" }}> <span style={{ fontWeight: "bold" }}>题目：</span> 全基因组测序揭示了与鸡高低温环境适应的新基因</p>
                <p style={{ fontSize: "12px", textAlign: "left" }}>
                  <span style={{ fontWeight: "bold" }}>发表期刊：</span>Journal of Advanced Research
                </p>
                <p style={{ fontSize: "12px", textAlign: "left" }}> <span style={{ fontWeight: "bold" }}>发表时间：</span> 2023年3月</p>
                <p style={{ fontSize: "12px", textAlign: "left", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  <span style={{ fontWeight: "bold" }}>研究结果：</span>
                  通过不同品种鸡的群体选择研究，发现发现其分化顺序遵循了由南往北的驯化轨迹，文昌鸡（WCC）比林甸鸡（LDC）更早分化。选择清除分析显示，SLC33A1和TSHR基因在文昌鸡基因组中表现出强受选择信号，这两个基因上的5个SNPs的等位基因频率与高温环境适应密切相关。此外，NDUFS4基因在LDC基因组中表现出强烈的正选择信号，且具有显著等位基因频率差异的SNP表明与寒冷环境适应密切相关。
                </p>
              </div>
            </div>
          </Swiper.Item>
          <Swiper.Item>
            <div className="cooper_item" style={{ width: size.width <= 750 ? "100%" : "", height: size.width <= 750 ? "180px" : "", marginLeft: size.width <= 750 ? "0" : "" }}>
              <img
                src={require("../../assets/img/cooperFour.jpg")}
                width={40 + "%"}
                height={80 + "%"}
                alt=""
                loading="lazy"
              />
              <div className="cooper_item_right">
                <p style={{ textAlign: "left" }}> <span style={{ fontWeight: "bold" }}>题目：</span> 大熊猫基因组揭示亚种分化时间以及特异性进化特征的遗传基础</p>
                <p style={{ fontSize: "12px", textAlign: "left" }}>
                  <span style={{ fontWeight: "bold" }}>发表期刊：</span>Science Bulletin
                </p>
                <p style={{ fontSize: "12px", textAlign: "left" }}> <span style={{ fontWeight: "bold" }}>发表时间：</span> 2021年10月</p>
                <p style={{ fontSize: "12px", textAlign: "left", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  <span style={{ fontWeight: "bold" }}>研究结果：</span>
                  本研究组装了最为完整的大熊猫两个亚种（四川亚种和秦岭亚种）的基因组，且后者基因组为首次组装，并且重新评估两者的分化时间在距今约1.0至1.2万年前。比较基因组学的分析发现，大熊猫基因组上的DACH2基因调控元件特异性丢失，并在SYT6基因发现特异性位点突变，可能是大熊猫这一物种的繁殖力低的重要原因。另外，大熊猫内脏器官适应性变小，或与基因有关。
                </p>
              </div>
            </div>
          </Swiper.Item>
        </Swiper>
      </div>
    } else {
      return <div className="cooper_bottom">
        <Button
          className="cooper_left"
          onClick={() => {
            cooper.current.prev();
          }}
        >
          <LeftCooper />
        </Button>
        <Carousel
          afterChange={onChange}
          style={{
            width: "100%",
            height: "18.125rem",
            margin: "0",
            padding: "0",
            position: "relative",
          }}
          ref={cooper}
          dots={false}
        >
          <div>
            <div style={contentStyletwo}>
              <div className="cooper_item">
                <img
                  src={require("../../assets/img/cooperOne.png")}
                  width={40 + "%"}
                  height={70 + "%"}
                  alt=""
                  loading="lazy"
                />
                <div className="cooper_item_right">
                  <p style={{ textAlign: "left" }}> <span style={{ fontWeight: "bold" }}>题目：</span> 首个梅花鹿高质量基因组揭示梅花鹿高单宁饮食的适应机制</p>
                  <p style={{ fontSize: "0.75rem", textAlign: "left" }}>
                    <span style={{ fontWeight: "bold" }}>发表期刊：</span>Genomics, Proteomics & Bioinformatics(GPB)
                  </p>
                  <p style={{ fontSize: "0.75rem", textAlign: "left" }}> <span style={{ fontWeight: "bold" }}>发表时间：</span> 2023年2月</p>
                  <p style={{ fontSize: "0.75rem", textAlign: "left" }}>
                    <span style={{ fontWeight: "bold" }}>研究结果：</span>
                    (1).组装得到目前已知最高质量的梅花鹿全基因组序列，序列总长约2.5 Gb，染色体水平基因组scaffold N50达到78.8 Mb。
                    (2).梅花鹿群体的地理分布与栎树的生长分布具有高度的一致性，并且梅花鹿对高单宁含量饲料(以栎树叶为主)的耐受性较高。
                    (3).UGT基因家族特别是UGT2B基因在梅花鹿基因组中显著扩张。
                    (4).UGT基因家族在梅花鹿肝脏中呈显著的高表达，阐述了UGT基因家族参与梅花鹿单宁代谢的可能分子机制。
                  </p>
                </div>
              </div>
              <div className="cooper_item">
                <img
                  src={require("../../assets/img/cooperTwo.png")}
                  width={40 + "%"}
                  height={40 + "%"}
                  alt=""
                  loading="lazy"
                />
                <div className="cooper_item_right">
                  <p style={{ textAlign: "left" }}> <span style={{ fontWeight: "bold" }}>题目：</span> 基因组学分析鉴定马铃薯重要农艺性状基因的分子标记</p>
                  <p style={{ fontSize: "0.75rem", textAlign: "left" }}>
                    <span style={{ fontWeight: "bold" }}>发表期刊：</span>Molecular Plant
                  </p>
                  <p style={{ fontSize: "0.75rem", textAlign: "left" }}> <span style={{ fontWeight: "bold" }}>发表时间：</span> 2018年2月</p>
                  <p className="cooper_text" style={{ fontSize: "0.75rem", textAlign: "left" }}>
                    <span style={{ fontWeight: "bold" }}>研究结果：</span>
                    该研究对201份马铃薯种质资源材料进行二代测序分析，结果表明（1）马铃薯野生群体具有很高的遗传多样性，尤其是抗病基因；（2）南美马铃薯地方栽培种是单系起源于秘鲁南部，鉴定出529个野生祖先在驯化过程丢失的与环境适应性相关的基因；（3）马铃薯野生群体被分为四个高度分化的亚群，该研究鉴定出几千个高度分化基因和609个驯化基因，包括与马铃薯苦味和块茎形成和发育相关的基因。以上为马铃薯重要农艺性状基因的鉴定及进一步分子育种提供了重要基础。
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style={contentStyletwo}>
              <div className="cooper_item">
                <img
                  src={require("../../assets/img/cooperThree.png")}
                  width={40 + "%"}
                  height={80 + "%"}
                  alt=""
                  loading="lazy"
                />
                <div className="cooper_item_right">
                  <p style={{ textAlign: "left" }}> <span style={{ fontWeight: "bold" }}>题目：</span> 全基因组测序揭示了与鸡高低温环境适应的新基因</p>
                  <p style={{ fontSize: "0.75rem", textAlign: "left" }}>
                    <span style={{ fontWeight: "bold" }}>发表期刊：</span>Journal of Advanced Research
                  </p>
                  <p style={{ fontSize: "0.75rem", textAlign: "left" }}> <span style={{ fontWeight: "bold" }}>发表时间：</span> 2023年3月</p>
                  <p style={{ fontSize: "0.75rem", textAlign: "left" }}>
                    <span style={{ fontWeight: "bold" }}>研究结果：</span>
                    通过不同品种鸡的群体选择研究，发现发现其分化顺序遵循了由南往北的驯化轨迹，文昌鸡（WCC）比林甸鸡（LDC）更早分化。选择清除分析显示，SLC33A1和TSHR基因在文昌鸡基因组中表现出强受选择信号，这两个基因上的5个SNPs的等位基因频率与高温环境适应密切相关。此外，NDUFS4基因在LDC基因组中表现出强烈的正选择信号，且具有显著等位基因频率差异的SNP表明与寒冷环境适应密切相关。
                  </p>
                </div>
              </div>
              <div className="cooper_item">
                <img
                  src={require("../../assets/img/cooperFour.jpg")}
                  width={40 + "%"}
                  height={80 + "%"}
                  alt=""
                  loading="lazy"
                />
                <div className="cooper_item_right">
                  <p style={{ textAlign: "left" }}> <span style={{ fontWeight: "bold" }}>题目：</span> 大熊猫基因组揭示亚种分化时间以及特异性进化特征的遗传基础</p>
                  <p style={{ fontSize: "0.75rem", textAlign: "left" }}>
                    <span style={{ fontWeight: "bold" }}>发表期刊：</span>Science Bulletin
                  </p>
                  <p style={{ fontSize: "0.75rem", textAlign: "left" }}> <span style={{ fontWeight: "bold" }}>发表时间：</span> 2021年10月</p>
                  <p style={{ fontSize: "0.75rem", textAlign: "left" }}>
                    <span style={{ fontWeight: "bold" }}>研究结果：</span>
                    本研究组装了最为完整的大熊猫两个亚种（四川亚种和秦岭亚种）的基因组，且后者基因组为首次组装，并且重新评估两者的分化时间在距今约1.0至1.2万年前。比较基因组学的分析发现，大熊猫基因组上的DACH2基因调控元件特异性丢失，并在SYT6基因发现特异性位点突变，可能是大熊猫这一物种的繁殖力低的重要原因。另外，大熊猫内脏器官适应性变小，或与基因有关。
                  </p>
                </div>
              </div>
            </div>
          </div>

        </Carousel>
        <Button
          onClick={() => {
            cooper.current.next();
          }}
          className="cooper_right"
        >
          <RightCooper />
        </Button>
      </div>
    }
  }
  const AboutSwiper = () => {
    if (size.width <= 750) {
      return <div className="About_Swiper">
        <Swiper>
          <Swiper.Item style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className="about_items items_one" style={{ width: size.width <= 750 ? "100%" : "", }}>
              <p className="about_items_title ">百奥华兴</p>
              <img
                className="bio_img"
                src={require("../../assets/img/firm1 - 副本.png")}
                width={75 + "%"}
                alt=""
                loading="lazy"
              />
              <p style={{ width: size.width <= 750 ? "100%" : "", height: size.width <= 750 ? "100px" : "", fontSize: ".875rem", padding: "0 1.5625rem", textIndent: "2rem", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", WebkitLineClamp: "5" }}>
                百奥华兴创立于2023年，以基因大数据智算软件与智能平台为核心，专注人类疾病的精准诊断及生物育种研究。团队成员来自顶尖高校，先后在NG、NC、PNAS和MP等学术期刊发表相关文章上百篇。凭借强大的科研能力、自有平台和数据资源，研发智算加速软件和一站式育种平台，致力于开发突破性的疾病诊断软件和颠覆性的育种技术。自始至终，我们坚持自主知识产权的智算技术研发，为人类健康和农业发展提供科技支持。
              </p>
            </div>
          </Swiper.Item>
          <Swiper.Item style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div className="about_items items_two" style={{ width: size.width <= 750 ? "100%" : "", }}>
          <p className="about_items_title ">高性能超算中心</p>
          <img
            src={require("../../assets/img/6e5238f63229b368caac045d3f0e320.png")}
            width={70 + "%"}
            alt=""
            loading="lazy"
          />
          <p style={{ fontSize: ".875rem", padding: "0 1.5625rem", textIndent: "2rem",width: size.width <= 750 ? "100%" : "", height: size.width <= 750 ? "100px" : "", fontSize: ".875rem", padding: "0 1.5625rem", textIndent: "2rem", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", WebkitLineClamp: "5" }}>
            百奥华兴高性能超算中心（High Performance Supercomputer Center,
            HPSC）采用A100 GPU、Intel
            9代CPU和分布式存储的高效组合，实现极速稳定的基因大数据分析、算法实现及交付。本地和云计算资源高效支撑智能育种运算平台。随着公司业务的发展，高性能超算中心将会持续升级，
            以保证高效的基因大数据处理和安全的数据存储。 13，040个物理核数
            2,526 Tflops 计算峰值速度 80 TB 总内存 10PB 总储存
          </p>
        </div>
          </Swiper.Item>
        </Swiper>
      </div>
    } else {
      return <div className="About_Swiper">
        <div className="about_items items_one">
          <p className="about_items_title ">百奥华兴</p>
          <img
            className="bio_img"
            src={require("../../assets/img/firm1 - 副本.png")}
            width={75 + "%"}
            alt=""
            loading="lazy"
          />
          <p style={{ fontSize: ".875rem", padding: "0 1.5625rem", textIndent: "2rem" ,lineHeight:"1.5625rem"}}>
            百奥华兴创立于2023年，以基因大数据智算软件与智能平台为核心，专注人类疾病的精准诊断及生物育种研究。团队成员来自顶尖高校，先后在NG、NC、PNAS和MP等学术期刊发表相关文章上百篇。凭借强大的科研能力、自有平台和数据资源，研发智算加速软件和一站式育种平台，致力于开发突破性的疾病诊断软件和颠覆性的育种技术。自始至终，我们坚持自主知识产权的智算技术研发，为人类健康和农业发展提供科技支持。
          </p>
        </div>
        <div className="center_hr"></div>
        <div className="about_items items_two">
          <p className="about_items_title ">高性能超算中心</p>
          <img
            src={require("../../assets/img/6e5238f63229b368caac045d3f0e320.png")}
            width={70 + "%"}
            alt=""
            loading="lazy"
          />
          <p style={{ fontSize: ".875rem", padding: "0 1.5625rem", textIndent: "2rem",lineHeight:"1.5625rem" }}>
            百奥华兴高性能超算中心（High Performance Supercomputer Center,
            HPSC）采用A100 GPU、Intel
            9代CPU和分布式存储的高效组合，实现极速稳定的基因大数据分析、算法实现及交付。本地和云计算资源高效支撑智能育种运算平台。随着公司业务的发展，高性能超算中心将会持续升级，
            以保证高效的基因大数据处理和安全的数据存储。 13，040个物理核数
            2,526 Tflops 计算峰值速度 80 TB 总内存 10PB 总储存
          </p>
        </div>
      </div>
    }
  }
  return (
    <div className="Home_app">
      <Carousel ref={swiperRef} autoplay>
        <div>
          <div style={contentStyle}>
            <img
              src={require("../../assets/img/图片23.png")}
              width={100 + "%"}
              height={100 + "%"}
              alt=""
              loading="lazy"
            />
            <div
              className="BannerCenter_div"
              style={{
                zIndex: "99",
                display: "flex",
                alignItems: "center",
                position: "absolute",
                width: "37%",
                height: "30%",
                background: "rgba(0,0,0,0.5)",
                left: "5%",
                borderRadius: "0.625rem",
              }}
            >
              <div className="Home_left"></div>
              <div className="Home_center">
                百奥华兴为您提供领先的基因大数据解决方案
              </div>
              <div className="Home_right"></div>
            </div>
          </div>

        </div>

      </Carousel>
      <div className="Hot_products">
        <div className="Hot_products_child">
          <div className="hot_title" style={{left:size.width<=750?"33%":""}}>热点产品</div>
          <div className="hot_bottom" style={{ flexDirection: size.width <= 750 ? "column" : "" }}>
            <div className="hot_items" style={{ width: size.width <= 750 ? "96%" : "" }}>
              <h2>T2T基因组组装</h2>
              <img
                src={require("../../assets/img/T2TThree.png")}
                width={80 + "%"}
                height={45 + "%"}
                alt=""
                loading="lazy"
              />
              <p className="hot_context">
                结合短读长和长读长测序技术，我们可以高效、低成本地完成物种T2T（Telomere-to-telomere）基因组测序和组装。基于全基因组序列图谱可以构建该物种的基因组数据库，为该物种的后基因组学研究搭建一个高效的平台，为后续的基因挖掘、功能验证提供DNA序列信息，推进该物种的研究。
              </p>
            </div>

            <div className="hot_items" style={{ width: size.width <= 750 ? "96%" : "" }}>
              <h2>ATGCbox</h2>
              <img
                src={require("../../assets/img/ATGbox.png")}
                width={80 + "%"}
                // height={80 + "%"}
                alt=""
                loading="lazy"
              />
              <p className="hot_context">
                ATGCbox是一款基于CUDA+GPU加速的基因数据处理一体机，支持胚系突变与体细胞突变的全基因组、全外显子组等多种类型高通量测序数据分析。我们提供本地部署，实现可视化平台操作，用户无需搭建本地化超算平台和运行环境，可自由选择运行的步骤、参数设置和流程版本，适用于广大非生物信息分析背景的用户。
              </p>
            </div>

            <div className="hot_items" style={{ width: size.width <= 750 ? "96%" : "" }}>
              <h2>分子育种平台</h2>
              <img
                src={require("../../assets/img/breeding.png")}
                width={80 + "%"}
                alt=""
                loading="lazy"
              />
              <p className="hot_context">
                智能化精准育种平台可以方便不同领域的科研和育种人员，在可视化的操作界面上充分利用已有的表型和基因型数据来构建重要性状的基因组预测模型。该平台可以获得基因组关联分析信号，同时提供适合不同物种的传统/机器学习/深度学习模型用于基因组预测和亲本精准选配，或者对不同物种进行亲缘关系最优组合选配。
              </p>
            </div>
            {/* <div className="gos">了解更多></div> */}
          </div>
        </div>
      </div>
      <div className="Cooperation_case">
        <div className="Cooperation_case_child">
          <div className="cooper_title" style={{ left: size.width <=750? "35%" : "" }}>合作案例</div>
            <div style={{color:"#ccc",position:"absolute",top:"13%",left:size.width<=750?"40%":"45.5%"}}>（部分结果展示）</div>
          {
            cooperBottom()
          }
        </div>
      </div>
      <div className="about_div" style={{marginTop:size.width<=750?"-8.125rem":""}}>
        <div className="about_div_child">
          <div className="about_title" style={{ width: size.width <= 750 ? "60%" : "" }}></div>
          {
            AboutSwiper()
          }
        </div>
      </div>
      <div className="newCenter_div" style={{height:size.width<=750?"200vh":""}}>
        <div className="newCenter_div_child">
          <div className="new_center_title" style={{ width: size.width <= 750 ? "60%" : "",height:size.width<=750?"5%":"",top:size.width<=750?"-3%":"" }}></div>
          <div className="newCenter_List" style={{flexDirection:size.width<=750?"column":""}}>
            {newList.map((v, i) => {
              return (
                <div key={v.id} className="newCenter_items" style={{width:size.width<=750?"90%":""}}>
                  <img
                    src={require(`../../assets/img/图片${v.img}.png`)}
                    width={85 + "%"}
                    style={{
                      width:size.width<=750?"60%":"85%"
                    }}
                    alt=""
                    loading="lazy"
                  />
                  <div>
                    <p>{v.time}</p>
                    <p>{v.title}</p>
                    <p className="new_Text" style={{width:size.width<=750?"15.625rem":"",textOverflow:"ellipsis",display:"-webkit-box",WebkitLineClamp:"2"}}>
                      12月15日，2023“数启京津·智汇武清”清数科技园推介会暨重点项目签约仪式在北京隆重举行，吸引了百奥华兴以及众多企业共同参与。与会者们共同探讨大数据行业的未来趋势，汇聚产业链上下游的合作伙伴，旨在共同推进京津冀协同发展背景下数字经济产业的繁荣。
                    </p>
                  </div>
                  <p
                    style={{
                      fontSize: "0.8125rem",
                      width: "100%",
                      display: "flex",
                      alignItems: "start",
                      marginTop: "1.25rem",
                      marginLeft: "1.875rem",
                    }}
                  >
                    <span>
                      <a target="_blank" rel="noopener noreferrer" href={"#/newcontent?name=" + v.id}>点击查看详情>></a>
                    </span>
                  </p>
                </div>
              );
            })}
            <p className="center_hr" style={{width:size.width<=750?"95%":"0.0625rem",height:size.width<=750?"0.0625rem":"95%"}}></p>
            <div className="newCenter_items" style={{width:size.width<=750?"90%":"",marginTop:size.width<=750?"1.25rem":"",marginBottom:size.width<=750?"1.25rem":""}}>
              <div>
                <p>2024年1月22日</p>
                <p>
                  百奥华兴应邀出席武清大数据产业创新联盟年会，智能加速软件备受瞩目
                </p>
                <p className="new_Text" style={{width:size.width<=750?"15.625rem":"",textOverflow:"ellipsis",display:"-webkit-box",WebkitLineClamp:"2"}}>
                  2024年1月18日下午，武清大数据产业创新联盟2023年会在京顺利举行，本次活动由高村科技创新园、武清大数据产业创新联盟、瀚海武清国际创新中心共同举办，是大数据联盟成立以来的首次年会，与众多成员单位以及数据领域企业代表共同回顾2023年工作成果，展望和探索2024年产业合作机会。
                </p>
              </div>
              <img
                src={require("../../assets/img/图片102.png")}
                width={90 + "%"}
                height={45 + "%"}
                style={{
                  width:size.width<=750?"70%":"90%",
                  height:size.width<=750?"50%":"45%"
                }}
                alt=""
                loading="lazy"
              />
              <p
                style={{
                  fontSize: "0.8125rem",
                  width: "100%",
                  display: "flex",
                  alignItems: "start",
                  marginTop:size.width<=750?"0": "4rem",
                  marginLeft: "1.875rem",
                }}
              >
                <a href="https://mp.weixin.qq.com/s/NZluYsyjhBNjEkcabKoJSA">
                  点击查看详情>>
                </a>
              </p>
            </div>
            <p className="center_hr" style={{width:size.width<=750?"95%":"0.0625rem",height:size.width<=750?"0.0625rem":"95%"}}></p>

            <div className="newCenter_items"  style={{width:size.width<=750?"90%":"",marginTop:size.width<=750?"1.25rem":"",marginBottom:size.width<=750?"1.25rem":""}}>
              <img
                src={require("../../assets/img/图片105.jpg")}
                width={80 + "%"}
                alt=""
                loading="lazy"
              />
              <div style={{
                margin:"1.875rem 0"
              }}>
                <p>2024年2月6日</p>
                <p>
                百奥华兴正式加入武清大数据产业创新联盟
                </p>
                <p className="new_Text" style={{width:size.width<=750?"15.625rem":"",}}>
                百奥华兴正式加入武清大数据产业创新联盟，未来将充分发挥自身的专业优势，与联盟成员携手，深入挖掘大数据的潜力，探索大数据技术的更多应用场景，推动产业数字化、智能化转型，为经济社会发展注入新的动力，推动产业不断向前发展。
                </p>
              </div>
              <p
                style={{
                  fontSize: "0.8125rem",
                  width: "100%",
                  display: "flex",
                  alignItems: "start",
                  // marginTop: "1.25rem",
                  // marginBottom:"-50px",
                  marginLeft: "1.25rem",
                }}
              >
                <a href="https://mp.weixin.qq.com/s/hC134y8QU4ijCZ3NUNQVMA">
                  点击查看详情>>
                </a>
              </p>
            </div>
            {/* <p className="center_hr"></p> */}

            {/* <div className="newCenter_items">

              <div>
                <p>2024年2月1日</p>
                <p>
                  新品发布|开启生信分析加速之旅，ATGCbox一体机让你走在科研前沿
                </p>
                <p className="new_Text">
                  ATGCbox是一款基于CUDA+GPU加速的基因数据处理一体机，支持胚系突变与体细胞突变的全基因组(WGS)、全外显子组(WES)等多种类型高通量测序数据分析。
                </p>
              </div>
              <img
                src={require("../../assets/img/图片103.png")}
                width={90 + "%"}
                alt=""
                loading="lazy"
              />
              <p
                style={{
                  fontSize: "0.8125rem",
                  width: "100%",
                  display: "flex",
                  alignItems: "start",
                  marginTop: "1.25rem",
                  marginLeft: "1.25rem",
                }}
              >
                <a href="">
                  点击查看详情>>
                </a>
              </p>
            </div> */}
          </div>
        </div>
      </div>

    </div>
  );
}

export default HomeIndex;

