import pdf1 from '../../assets/pdf/1.pdf'
import pdf2 from '../../assets/pdf/2.pdf'
import pdf3 from '../../assets/pdf/3.pdf'
import pdf4 from '../../assets/pdf/4.pdf'
import pdf5 from '../../assets/pdf/5.pdf'

import pdf7 from '../../assets/pdf/7.pdf'
import pdf8 from '../../assets/pdf/8.pdf'
import pdf9 from '../../assets/pdf/9.pdf'
import pdf10 from '../../assets/pdf/10.pdf'
import pdf11 from '../../assets/pdf/11.pdf'



const literatureList = [
    {
        id: 1,
        title: "1、2021-3-梅山猪基因组揭示了结构变异介导的基因表达和表型差异，这是亚洲猪驯化的基础        ",
        path: "/Pdf?name=",
        src: pdf1,
        img: "1",
        periodical: "Molecular Ecology Resources",
        time: "2021年8月",
        Author_unit: "",
        keyword: ""
    }, {
        id: 2,
        title: "2、基因组分析产生了用于鉴定马铃薯中重要农艺基因的标记",
        path: "/Pdf?name=",
        src: pdf2,
        img: "2",
        periodical: "Molecular Plant",
        time: "2018年3月",
        Author_unit: "",
        keyword: ""
    }, {
        id: 3,
        title: "3、基因组分析揭示了中国土鸡对炎热、干旱和恶劣环境的适应",
        path: "/Pdf?name=",
        src: pdf3,
        img: '3',
        periodical: "Frontiers in Genetics",
        time: "2020年12月",
        Author_unit: "",
        keyword: ""
    }, {
        id: 4,
        title: "4、马铃薯的基因组多样性",
        path: "/Pdf?name=",
        src: pdf4,
        img: "4",
        periodical: "Proc Natl Acad Sci USA",
        time: "2018年1月",
        Author_unit: "",
        keyword: ""
    }, {
        id: 5,
        title: "5、利用PacBio测序和Hi-C技术进行北极狐染色体水平的基因组组装",
        path: "/Pdf?name=",
        src: pdf5,
        img: "5",
        periodical: "Molecular Ecology Resources",
        time: "2021年3月",
        Author_unit: "",
        keyword: ""


    },{
        id: 7,
        title: "7、第一个高质量的梅花鹿参考基因组为高单宁适应提供了见解",
        path: "/Pdf?name=",
        src: pdf7,
        img: "7",
        periodical: "Genomics Proteomics Bioinformatics",
        time: "2023年2月",
        Author_unit: "",
        keyword: ""
    }, {
        id: 8,
        title: "8、肥胖差异选择下鸡基因组的微观进化动态",
        path: "/Pdf?name=",
        src: pdf8,
        img: "8",
        periodical: "iScience",
        time: "2022年6月",
        Author_unit: "",
        keyword: ""
    }, {
        id: 9,
        title: "2022年7月17日-全基因组分析揭示了与鸡适应热带和寒冷环境相关的新基因",
        path: "/Pdf?name=",
        src: pdf9,
        img: "9",
        periodical: "Journal of Advanced Research",
        time: "2023年3月",
        Author_unit: "",
        keyword: ""
    }, {
        id: 10,
        title: "2023-2-20-麻蜥",
        path: "/Pdf?name=",
        src: pdf10,
        img: "10",
        periodical: "BMC Biology",
        time: "2023年2月",
        Author_unit: "",
        keyword: ""
    }, {
        id: 11,
        title: "染色体规模的基因组为大熊猫的亚种分化和进化特征提供了新的见解",
        path: "/Pdf?name=",
        src: pdf11,
        img: "11",
        periodical: "Science Bulletin",
        time: "2021年10月",
        Author_unit: "",
        keyword: ""
    },
]
export default literatureList