import React, { useEffect, useState } from 'react'
import "./ListTag.model.css"
function ListTag(props) {
  const { title, items } = props

  return (
    <div className="menu">
      <span style={{padding:"0 5px"}}>{title}</span>
      <div className='Drop_div'>
        {
          items.map((v, i) => {
            return <div className="drop" key={v.key}>
             
              {v.label}
              
            </div>

          })
        }
   
      </div>
    </div>
  )
}

export default ListTag
