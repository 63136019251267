import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import rootReducer from "./reducers";

//2 异步中间件
import { thunk } from "redux-thunk";
// 2.1
import { composeWithDevTools } from "@redux-devtools/extension";

const persistConfig = {
  key: "root",
  storage,
  //   1 白名单  持久化
  whitelist: ["userInfo", "tags"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
let persistor = persistStore(store);
export { store, persistor };
