import React, { useCallback, useEffect, useState } from 'react'
import "./Text.model.css"
function TextIndex() {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <div className='text_app'>
      <div className='text_app_child'>
        <p className='text_p'>12月15日，2023“数启京津·智汇武清”清数科技园推介会暨重点项目签约仪式在北京隆重举行，吸引了百奥华兴以及众多企业共同参与。与会者们共同探讨大数据行业的未来趋势，汇聚产业链上下游的合作伙伴，旨在共同推进京津冀协同发展背景下数字经济产业的繁荣。</p>
        <p className='text_p'>本次活动在天津市人民政府驻北京办事处、武清区推进京津冀协同发展领导小组办公室的指导下，由武清区工信局、高村科技创新园主办，瀚海武清国际创新中心、武清大数据产业创新联盟承办。天津市驻京办、武清区协同办、人才办、工信局、科技局、网信办等相关负责人参加。活动旨在隆重推介清数科技园，举行重点项目签约仪式。 </p>
        <p className='text_p'>天津将“推进京津冀协同发展走深走实”作为市委市政府“十项行动”之首，武清区作为京津走廊上的重要节点，更是京津冀协同发展的桥头堡和主力军。<strong>天津市驻京办协同发展联络处处长尚国维</strong>在致辞中说：“高村科技园作为数字经济发展的重要平台，迎来了前所未有的机遇”未来，天津将继续大力优化京津冀区域产业布局，持续推进三地协同，相信武清的数字经济定会收获喜人的发展成果。</p>
        <img src={require('../../assets/img/newText1.png')} width={size.width<=750?"95%":""} alt="" />
        <p className='text_img_p'>天津市驻京办协同发展联络处处长尚国维致欢迎辞</p>
        <p className='text_p'><strong>武清区人才办赵磊科长</strong>做《武清区人才发展环境》政策解读，为参会企业详细介绍了武清区“以人为本”的人才政策体系，武清区以服务高质量发展为主线，深化人才体制机制改革，着力构建人才全面发展生态。四张“人才服务清单”，涵盖了人才落地能够享受到的全方位保障，用真金白银打造平台，用真材实料支持项目，用真抓实干成就人才，开放怀抱、筑巢引凤，期待高端人才的青睐。</p>
        <img src={require('../../assets/img/newText2.png')} width={size.width<=750?"95%":""} alt="" />
        <p className='text_img_p'>武清区人才办赵磊科长介绍人才政策</p>
        <p className='text_p'>清数科技园启动环节中，由<strong>天津市驻京办协同发展联络处处长尚国维、高村科技创新园总经理杜瀛涛、瀚海控股集团常务副总裁赵振江、利亚德集团副总裁陆怀鹏，</strong>分别代表政府、园区、平台、链主，共同启动了清数科技园的正式运营。清数科技园由高村科技创新园自主投资建设，在环京最具区位优势地带打造<strong>14</strong>万平米智慧载体，以强大的区位优势、算力规模、产业政策、创新生态，持续吸引着京津冀大数据企业的目光，为区域发展带来了新的动能和增长点。未来可期，相信在不久的将来，清数科技园必将成为京津发展主轴线上大数据产业的新高地。</p>
        <p className='text_p'>
        百奥华兴创立于<strong>2023</strong>年，以基因大数据智算软件与平台开发为核心，专注高通量测序的医学精准诊断及生物育种研究。凭借强大的科研能力、自有平台和数据资源，研发智算加速软件和一站式育种平台，并致力于开发突破性的疾病诊断软件和颠覆性的育种技术。公司始终坚持自主知识产权的智算技术研发，为人类健康和农业发展提供科技支持。作为基因科技智算平台型企业，开发完善的生物育种大数据智算基础设施平台，为行业提供一站式智算解决方案。在生物育种市场取得成功的基础上，我们依托自主研发的智算软件和平台，积极进军分子诊断领域。在分子诊断产品研发方面，我们专注于临检产品和试剂盒的开发，同时不断探索药物研发和合成生物学等领域。未来智算软件和平台将广泛应用于生物育种和分子诊断领域，并不断拓展核心产品在癌症早筛、精准医疗、药物研发和智能育种等细分市场中的应用。
        </p>
    </div> 
    </div>
  )
}

export default TextIndex