import React from 'react'
import './newcenter.model.css'
function NewCenterIndex() {
  return (
    <div className='newcenter_app'>
        <div className='newCenter_title'></div>
    </div>
  )
}

export default NewCenterIndex
