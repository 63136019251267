import React from 'react'
import './contact.model.css'
function ContactIndex() {
  return (
    <div className='contact_app'>
     <div className='contact_title'></div>
    </div>
  )
}

export default ContactIndex