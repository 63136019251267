const initialState = {
  token: "",
  menus: [],
  unique_auth: [],
  user_info: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "rest_userInfo": {
      return initialState;
    }
    case "set_userInfo": {
      return payload;
    }
    default:
      return state;
  }
};
