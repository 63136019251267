const NewJson=[
    {
        'id':"0",
        "title":"数启京津·智汇武清｜百奥华兴助力清数科技园盛会，武清数字经济迈向新篇章",
        'time':"2023年12月16日",
        "img":'31',
    },

]

export default NewJson


