const itemThree=[
  {
    "key": "1",
    "label": (
      <a style={{display:"block",width:"100%",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}} target="" rel="noopener noreferrer" href="">
        基因编辑工具开发及优化
      </a>
    )
  },
  {
    "key": "2",
    "label": (
      <a style={{display:"block",width:"100%",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}} target="" rel="noopener noreferrer" href="#/Biohuaxing/Detection/">
        脱靶检测
      </a>
    )
  },
  {
    "key": "3",
    "label": (
      <a style={{display:"block",width:"100%",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}} target="" rel="noopener noreferrer" href="">
        工程菌改造
      </a>
    )
  },
  {
    "key": "4",
    "label": (
      <a style={{display:"block",width:"100%",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}} target="" rel="noopener noreferrer" href="">
        蛋白结构解析
      </a>
    )
  }
]

export default itemThree