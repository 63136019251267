import React from "react";
import "./Functional.model.css";
import { Empty } from "antd";
function FunctionalIndex() {
  return (
    <div className="Functional_app">
      <div className="func_title">
        <h1>蛋白质组学</h1>
      </div>
      <div className="Qualitative_protein">
        <div className="Qualitative_protein_child"> 
           <p className="modul_title">定性蛋白质组</p>
          
           <div className="Basic_information">
            <p>基本信息分析内容</p>
            <ol>
              <li>质谱分析参数、鉴定结果以及数据质控结果；</li>
              <li>蛋白质鉴定结果（Unique肽段数分析；肽段长度分析；蛋白覆盖度分析；母离子质量容差分布）；</li>
              <li>蛋白质功能分析（GO功能注释；KEGG功能注释；COG功能注释）；</li>
            </ol>
           </div>
        </div>
      </div>
      <div className="Qualitative_protein">
        <div className="Qualitative_protein_child"> 
           <p className="modul_title">定量蛋白质组</p>
        
           <div className="Basic_information">
            <p>基本信息分析内容</p>
            <ol>
              <li>质谱分析参数、鉴定结果以及数据质控结果；</li>
              <li>总蛋白质的功能注释（GO功能注释、KEGG功能注释、COG功能注释、蛋白结构域注释、亚细胞定位分析）；</li>
              <li>蛋白定量分析结果；</li>
              <li>差异表达蛋白的筛选结果（火山图分析、聚类热图分析、C-means分析）；</li>
              <li>差异表达蛋白的功能富集分析（GO富集分析、KEGG富集分析、结构域富集分析、差异蛋白亚细胞定位分析）；-means分析）；</li>
              <li>差异表达蛋白互作网络分析；</li>
            </ol>
           </div>
        </div>
      </div>
      <div className="Qualitative_protein">
        <div className="Qualitative_protein_child"> 
           <p className="modul_title">Label-free定量蛋白质组</p>
        
           <div className="Basic_information">
            <p>基本信息分析内容</p>
            <ol>
              <li>质谱分析参数、鉴定结果以及数据质控结果；</li>
              <li>总蛋白质的功能注释（GO功能注释、KEGG功能注释、COG功能注释、蛋白结构域注释、亚细胞定位分析）；</li>
              <li>蛋白定量分析结果；</li>
              <li>差异表达蛋白的筛选结果（火山图分析、聚类热图分析、C-means分析）；-means分析）；</li>
              <li>差异表达蛋白的功能富集分析（GO富集分析、KEGG富集分析、结构域富集分析、差异蛋白亚细胞定位分析）；-means分析）；</li>
              <li>差异表达蛋白互作网络分析；</li>
            </ol>
           </div>
        </div>
      </div>
      <div className="Qualitative_protein">
        <div className="Qualitative_protein_child"> 
           <p className="modul_title">DIA定量蛋白质组</p>
         
           <div className="Basic_information">
            <p>基本信息分析内容</p>
            <ol>
              <li>质谱分析参数、鉴定结果以及数据质控结果；</li>
              <li>总蛋白质的功能注释（GO功能注释、KEGG功能注释、COG功能注释、蛋白结构域注释、亚细胞定位分析）；</li>
              <li>蛋白定量分析结果；</li>
              <li>差异表达蛋白的筛选结果（火山图分析、聚类热图分析、C-means分析）；</li>
              <li>差异表达蛋白的功能富集分析（GO富集分析、KEGG富集分析、结构域富集分析、差异蛋白亚细胞定位分析）；-means分析）；</li>
              <li>差异表达蛋白互作网络分析；</li>
            </ol>
           </div>
        </div>
      </div>
      <div className="Decorate_title">
        <h2>修饰蛋白质组学</h2>
      </div>
      <div className="Decorate_protein">
        <div className="Decorate_protein_child"> 
           <p className="modul_title">修饰定性蛋白质组学</p>
         
           <div className="Basic_information">
            <p>基本信息分析内容</p>
            <ol>
              <li>质谱分析参数、鉴定结果以及数据质控结果；</li>
              <li>蛋白质鉴定结果（Unique肽段数分析；肽段长度分析；蛋白覆盖度分析；母离子质量容差分布）；</li>
              <li>蛋白质功能分析（GO功能注释；KEGG功能注释；COG功能注释）；</li>
            </ol>
           </div>
        </div>
      </div>
      <div className="Phosphorylation_modification">
        <div className="Phosphorylation_modification_child"> 
           <p className="modul_title">磷酸化修饰</p>
        
           <div className="Basic_information">
            <p>基本信息分析内容</p>
            <ol>
              <li>质谱分析参数、鉴定结果以及数据质控结果；</li>
              <li>总修饰蛋白质的功能注释（GO功能注释、KEGG功能注释、COG功能注释、蛋白结构域注释、亚细胞定位分析）；</li>
              <li>修饰蛋白定量分析结果；</li>
              <li>修饰位点个数、种类、分布位置统计；</li>
              <li>motif分析；</li>
              <li>差异修饰位点筛选；</li>
              <li>差异表达的修饰蛋白筛选结果（火山图分析、聚类热图分析、C-means分析）；</li>
              <li>差异修饰蛋白的功能富集分析（GO富集分析、KEGG富集分析、结构域富集分析、差异蛋白亚细胞定位分析）；</li>
              <li>差异修饰蛋白互作网络分析；</li>
            </ol>
           </div>
        </div>
      </div>
      <div className="Phosphorylation_modification">
        <div className="Phosphorylation_modification_child"> 
           <p className="modul_title">乙酰化、泛素化、N-糖基化修饰</p>
          
           <div className="Basic_information">
            <p>基本信息分析内容</p>
            <ol>
              <li>质谱分析参数、鉴定结果以及数据质控结果；</li>
              <li>总修饰蛋白质的功能注释（GO 功能注释、KEGG 功能注释、COG功能注释、蛋白结构域注释、亚细胞定位分析）；</li>
              <li>修饰蛋白定量分析结果；</li>
              <li>修饰位点个数统计；</li>
              <li>motif 分析；</li>
              <li>差异修饰位点筛选；</li>
              <li>差异表达的修饰蛋白筛选结果（火山图分析、聚类热图分析、C-means分析）；</li>
              <li>差异修饰蛋白的功能富集分析（GO 富集分析、KEGG富集分析、结构域富集分析、差异蛋白亚细胞定位分析）；</li>
              <li>差异修饰蛋白互作网络分析；</li>
            </ol>
           </div>
        </div>
      </div>
      <div className="demo_jx">
        <h2>案例解析</h2>
      </div>
      <div className="demo_twoP">
       <div className="demo_twoP_child">
       <p>案例:泛素化修饰在衰老中的动态变化及对寿命的影响</p>
        <p>
          <span>发票期刊：Nature </span><span>测序策略：泛素化修饰蛋白组</span>
        </p>
        <p className="result_p">研究结果</p>
        <p className="result_content">科研人员使用特异性抗体识别泛素化修饰肽段、并通过质谱鉴定获得该修饰的位点和定量信息，一共鉴定到了1,485种蛋白的3,373个肽段的泛素化修饰位点。研究发现许多肽段的泛素化水平在衰老过程中发生变化：野生型中整体泛素化水平下调，而且下调的肽段数目和比例都明显高于长寿线虫（图1）。同时作者发现这些肽段的泛素化水平改变不是由于蛋白丰度的改变引起的，这提示正常衰老过程中整体蛋白组的泛素化修饰发生丢失，且丢失程度很可能与衰老程度相关。</p>
          <img src={require('../../assets/img/图片82.png')} width={50+'%'} style={{margin:"0.625rem auto"}} alt="" loading="lazy"  />
          <p className="img_p">
            <span>图1 泛素化水平在不同遗传背景下随着衰老发生变化（eat-2, daf-2为长寿型）</span>
            <span>上：泛素化肽段数目和比例的变化 下：整体泛素化水平的变化</span>
          </p>
          <p className="result_content">为了回答泛素化水平的改变是否影响衰老，作者们首先尝试解决什么引起了泛素化水平的下调。由于泛素化酶和去泛素化酶（DUBs）直接调控泛素化水平，而随着衰老变化的去泛素化酶比例明显高于泛素化酶（31% vs 7%），由此推断DUBs活性的增加很大程度上引起了泛素化水平下调。本文发现广谱的DUBs抑制剂可以恢复衰老中下调的泛素化水平并延长寿命，这说明去泛素化酶引起的泛素化水平的下调促进了衰老。</p>
          <p className="result_content">为了回答泛素化水平的改变是否影响衰老，作者们首先尝试解决什么引起了泛素化水平的下调。由于泛素化酶和去泛素化酶（DUBs）直接调控泛素化水平，而随着衰老变化的去泛素化酶比例明显高于泛素化酶（31% vs 7%），由此推断DUBs活性的增加很大程度上引起了泛素化水平下调。本文发现广谱的DUBs抑制剂可以恢复衰老中下调的泛素化水平并延长寿命，这说明去泛素化酶引起的泛素化水平的下调促进了衰老。</p>
            <img src={require('../../assets/img/图片83.png')} width={50+"%"} style={{margin:"0.625rem auto"}} alt="" loading="lazy" />
            <p className="img_p">
              <span>图2 蛋白酶体靶标蛋白的积累是泛素化水平降低促进衰老的原因</span>
              <span>左：随年龄失调的蛋白酶体靶标 右：靶蛋白IFB-2和EPS-8对寿命的影响</span>
            </p>
       </div>
      </div>
    </div>
  );

}

export default FunctionalIndex;
