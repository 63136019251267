import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import "./App.css";
import MainLayOut from "./MainLayOut";
import HomeIndex from "./pages/Home";
// import BriefIndex from "./pages/Brief";
import PresentIndex from "./pages/Present";
import IlluminaTwoIndex from "./pages/IlluminaTwo";
import PacBioIndex from "./pages/PacBio";

import SequencingIndex from "./pages/Sequencing";
import MultiIndex from "./pages/Multi";
import FunctionalIndex from "./pages/Functional";
import SpeciesIndex from "./pages/Species";
import ChipIndex from "./pages/Chip";
import GenomicIndex from "./pages/Genomic";
// import GermplasmIndex from "./pages/Resources";
import ResourcesIndex from "./pages/Resources";
import ExcavateIndex from "./pages/Excavate";
import IntelligentIndex from "./pages/Intelligent";
import DetectionIndex from "./pages/Detection";
import DeveOptIndex from "./pages/DeveOpt";
import TransformationIndex from "./pages/Transformation";
import ProteinIndex from "./pages/Protein";
import VariationIndex from "./pages/Variation";
import Pdf from "./pages/Pdf";
import PangenesisIndex from "./pages/Pangenesis";
import MolecularIndex from "./pages/Molecular";
import AnalysisIndex from "./pages/Analysis";
import AnimalsIndex from "./pages/Animals";
import TemaIndex from "./pages/Tema";
import TechnologyIndex from "./pages/Technology";
import ResearchIndex from "./pages/Research";
import InvestorIndex from "./pages/Investor";
import PatentIndex from "./pages/Patent";
import NewCenterIndex from "./pages/NewCenter";
import ContactIndex from "./pages/Contact";
import NewsContentIndex from "./pages/NewsContent";
import { Button, Result } from "antd";

const AboutThePage = lazy(() => import("./pages/MFMGP/AboutThe"));
const LoginPage = lazy(() => import("./pages/MFMGP/Login"));
const RegisterPage = lazy(() => import("./pages/MFMGP/Register"));
const TaskListPage=lazy(()=>import("./pages/MFMGP/TaskList"))
const AccountPages=lazy(()=>import("./pages/MFMGP/Account"))
const MapPages=lazy(()=>import("./pages/MFMGP/Map"))

const LoadingPages=lazy(()=>import('./components/Loading'))

function App() {
  const navitage=useNavigate()
  return (
    <Suspense fallback={<LoadingPages/>}>
      <Routes>
        <Route path="/Biohuaxing" element={<MainLayOut />}>
        
         {/* 首页 */}
         <Route path="home" element={<HomeIndex />} ></Route>

         {/* <Route path="/Brief" element={<BriefIndex />} /> */}

         {/* 公司简介 */}
         <Route path="present" element={<PresentIndex />} />

         {/* 二代建库测序服务 */}
         <Route path="Illm" element={<IlluminaTwoIndex />} />

         {/* 三代库测序 */}
         <Route path="pacbio" element={<PacBioIndex />} />

         {/* 动植物基因组测序 */}
         <Route path="Genomics" element={<GenomicIndex />} />

         {/* 单细胞测序 */}
         <Route path="sequencing" element={<SequencingIndex />} />

         {/* <Route path="/Multi" element={<MultiIndex />} /> */}

         {/* 功能基因 */}
         <Route path="Functional" element={<FunctionalIndex />} />

         {/* 种质资源评价 */}
         <Route path="Resources" element={<ResourcesIndex />} />

         {/* <Route path="/species" element={<SpeciesIndex />} /> */}

         <Route path="Chip" element={<ChipIndex />} />

         {/* 多组学大数据功能位点挖掘数据库平台 */}
         <Route path="Excavate" element={<ExcavateIndex />} />

         {/* 基因组选择育种 */}
         <Route path="Intellgent" element={<IntelligentIndex />} />

         {/* 基因编辑工具开发与优化 */}
         <Route path="Deveopt" element={<DeveOptIndex />} />

         {/* 脱靶检测 */}
         <Route path="Detection" element={<DetectionIndex />} />

         {/* 工程菌改造 */}
         <Route path="Transformation" element={<TransformationIndex />} />

         {/* 蛋白结构解析 */}
         <Route path="Protein" element={<ProteinIndex />} />

         {/* 变异 */}
         <Route path="Variation" element={<VariationIndex />} />

         {/* 展示pdf文件 */}
         <Route path="Pdf" element={<Pdf />} />

         {/* 泛基因组测序（Pan-genome） */}
         <Route path="Pang" element={<PangenesisIndex />} />

         {/* 分子身份证开发 */}
         <Route path="Mole" element={<MolecularIndex />} />

         {/* 多组学大数据联合分析 */}
         <Route path="analysis" element={<AnalysisIndex />} />

         {/* 动植物全基因组重测序 */}
         <Route path="animals" element={<AnimalsIndex />} />

         {/* 团队 */}
         <Route path="tema" element={<TemaIndex />} />

         {/* 技术平台 */}
         <Route path="techno" element={<TechnologyIndex />} />

         {/* 研究成果 */}
         <Route path="research" element={<ResearchIndex />} />

         {/* 投资者关系 */}
         <Route path="investore" element={<InvestorIndex />} />

         {/* 资质专利 */}
         <Route path="patent" element={<PatentIndex />} />

         {/* 新闻中心 */}
         <Route path="newscenter" element={<NewCenterIndex />} />

         {/* 联系我们 */}
         <Route path="contact" element={<ContactIndex />} />

         {/* 新闻内容 */}
         <Route path="newcontent" element={<NewsContentIndex />} />
        </Route>

        <Route
          path="/404"
          element={
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
              extra={
                <Button
                  type="primary"
                  onClick={() => {
                    navitage("/");
                  }}
                >
                  Back Home
                </Button>
              }
            />
          }
        />
        <Route path="/403" element={<div>403用户无权限</div>} />
        {/* <Route path="*" element={<Navigate to="/404" />} /> */}
        <Route path="/" element={<Navigate to="/Biohuaxing/home/" />} />
        <Route path="MFMGP" element={<AboutThePage/>}/>
        <Route path="login" element={<LoginPage/>}/>
        <Route path="register" element={<RegisterPage/>}/>
        <Route path="tasklist" element={<TaskListPage/>}/>
        <Route path="account" element={<AccountPages/>}/>
      </Routes>
    </Suspense>
  );
}
export default App;
