import React from 'react'
import './research.model.css'
function ResearchIndex() {
  return (
    <div className='research_app'>
      <div className='research_title'></div>
    </div>
  )
}

export default ResearchIndex
