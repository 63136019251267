import React, { useCallback, useEffect, useState } from "react";
// "proxy": "http://47.93.101.203/",
import "./head.model.css";

import LogoImg from "../../assets/logo.jpg";

import TecgData from "../../assets/Json/itemOne";

import Breeding from "../../assets/Json/itemTwo";

import drug from "../../assets/Json/itemThree";

import life from "../../assets/Json/itemFour";

import about from "../../assets/Json/itemFive";

import MFMGPJson from '../../assets/Json/itemSix'

import ListTag from "../ListTag";
import Mobile from "../MobileTag";
function Header() {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const RightList = () => {
    if (size.width <= 750) {
      return <Mobile ItemsOne={TecgData} ItemsTwo={Breeding} ItemsThree={drug} ItemsFour={life} ItemsFive={about} />;
    } else {
      return (
        <ul className="head_list">
          <li>
            <a
              style={{
                textDecoration: "none",
                color: "#000",
                color: "#000",
                fontSize: "0.8125rem",
              }}
              href="/"
            >
              首页
            </a>
          </li>
          <li>
            {/* <TechIndex items={TecgData} title='科技服务产品' /> */}
            <ListTag title="科技服务产品" items={TecgData} />
          </li>
          <li>
            {/* <TechIndex items={Breeding} title='生物育种大数据平台开发' /> */}
            <ListTag title="生物育种大数据平台开发" items={Breeding} />
          </li>
          <li>
            {/* <TechIndex items={drug} title="药物研发" /> */}
            <ListTag title="药物研发" items={drug} />
          </li>
          <li>
            {/* <TechIndex items={life} title="生命科学基础设施平台" /> */}
            <ListTag title="生命科学基础设施平台" items={life} />
          </li>
          <li>
            <ListTag title="MFMGP" items={MFMGPJson} />
          </li>
          <li>
            {/* <TechIndex items={about} title="关于百奥华兴" /> */}
            <ListTag title="关于百奥华兴" items={about} />
          </li>
          <li>
            <a target="" href="#/contact" style={{ fontSize: "0.875rem" }}>
              联系我们
            </a>
          </li>
        </ul>
      );
    }
  };
  return (
    <div className="head_div" style={size.width <= 750 ? {} : {}}>
      <div className="head_left" onClick={() => {}}>
        {" "}
        <a href="/">
          <img className="logo_img" src={LogoImg} width={30 + "%"} alt="" />
        </a>
      </div>
      {RightList()}
    </div>
  );
}

export default Header;
