const itemFour=[
  {
    "key": "1",
    "label": (
      <a style={{display:"block",width:"100%",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}} target="" rel="noopener noreferrer" href="#/Biohuaxing/Variation/">
        ATGCbox
      </a>
    )
  }
]
export default itemFour