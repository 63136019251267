import React, { useCallback, useEffect, useState } from "react";
import "./pang.model.css";
function PangenesisIndex() {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <div className="pang_app">
      <div className="pang_title">
        <h1>泛基因组测序（Pan-genome）</h1>
      </div>
      <div className="easy_title">
        <div className="easy_title_child">
          <p>
            泛基因组由Tettelin等人于2005年首次在细菌中提出，指一个生物分支(如一个物种)的全部基因组信息，可分为由所有个体共享的核心基因组和部分个体共享或个体特异性的非必需基因组组成。2007年，植物泛基因组的概念被提出；2009年泛基因组的概念被应用到人中。泛基因组包括核心基因组（Core
            genome）和非必须基因组(Dispensable
            genome)。其中，核心基因组由所有样本中都存在的序列组成，一般与物种生物学功能和主要表型特征相关，反映了物种的稳定性；非必须基因组由仅在单个样本或部分样本中存在的序列组成，一般与物种对特定环境的适应性或特有的生物学特征相关，反映了物种的特性。
          </p>
          <p>
            运用高通量测序及生物信息分析手段，针对不同种/亚种/品系材料进行文库构建及三代长度长测序，并分别进行组装，可以构建泛基因组图谱。基于该图谱可以构建图形变异库，包括SV和PAV变异，丰富该物种的遗传信息，研究其重要生物学问题。
          </p>
          <img src={require("../../assets/img/图片32.png")} style={{
            width:size.width<=750?"100%":"30%"
          }} alt="" loading="lazy" />
          <div className="demo_time">
            <p>项目周期：</p>
            <p>10个基因组以内4个月，更多基因组数目周期另议。</p>
          </div>
        </div>
      </div>
      <div className="demo_parse">
        <div className="demo_parse_child">
          <h3 className="demo_parse_title">案例解析</h3>
          <div className="demo_periodical">
            <p>案例:栽培和野生大豆泛基因组</p>
            <p>
              <span style={{paddingRight:size.width<=750?"":"2.5rem"}}>发表期刊：cell</span>
              <span style={{paddingRight:size.width<=750?"":"2.5rem"}}>组装策略：PacBio+HiC </span>
            </p>
            <p></p>
          </div>
          <div className="Findings_div">
            <p className="find_title">研究结果</p>
            <p className="find_context">
              对来自世界大豆主产国的2,898个大豆种质材料进行了深度重测序和群体结构分析，精心挑选出26个最具代表性的大豆种质材料，包括3个野生大豆，9个农家种和14个现代栽培品种。采用最新组装策略进行高质量的基因组从头组装和精确注释。在此基础上，结合已经发表的3个大豆基因组，进行了泛基因组研究，构建高质量的图形基因组，挖掘到大量利用单个基因组作为参考序列，用二代数据进行比对不能鉴定的大片段结构变异。同时在重测序群体中对图形基因组的结构变异进行分型，结合来自上述26个材料的RNA-seq数据，定位重要农艺性状相关基因的遗传变异。结果表明，结构变异在重要农艺性状调控中发挥重要作用：例如，HPS基因的结构变异调控大豆种皮亮度变化；野生与栽培大豆CHS基因簇的结构变异是导致种皮颜色由黑色向黄色驯化的主要原因；SoyZH13_14G179600基因结构变异导致了其在不同种质材料中基因表达的差异，可能与调控大豆缺铁失绿症有关。此外，研究还鉴定到15个结构变异导致了不同基因间的融合，这为新基因的产生研究提供了重要线索。此高质量图形泛基因组的构建具有重要的理论意义和应用价值，能够促进大豆的进化和功能基因组研究。
            </p>
            <img src={require('../../assets/img/图片33.png')} style={{width:size.width<=750?"100%":""}} alt="" loading="lazy" />
            <p style={{width:"100%",height:"3%",display:"flex",alignItems:"center",justifyContent:"center"}}>图 大豆图形基因组研究</p>
              <p style={{
                width:"100%",
                marginTop:"0.625rem"
              }}>参考文献：Liu Y, Du H, Li P, et al. Pan-Genome of Wild and Cultivated Soybeans. Cell. 2020;182(1):162-176.e13. doi:10.1016/j.cell.2020.05.023.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PangenesisIndex;
