import React, { useCallback, useEffect, useState } from 'react'
import "./detection.model.css"
function DetectionIndex() {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <div className='detection_app'>
      <div className='detection_title'>
        <h1>Hi-TOM基因编辑位点检测</h1>
      </div>
      <div className="Product_introduction">
        <div className="Product_introduction_child">
          <p>1、产品介绍</p>
          <p>
            植物或动物基因编辑后，利用该方法检测编辑位点的情况，检验是否编辑成功，也可用于目标SNP变异检测。可以通过PCR法自动完成高通量建库过程，并用Hi-TOM在线软件直接解析出多样品多位点的变异信息。
          </p>
        </div>
      </div>
      <div className="Kit_introduction">
        <div className="Kit_introduction_child">
          <p>2、使用试剂盒介绍</p>
          <img
            src={require("../../assets/img/图片86.png")}
            style={{width:size.width<=750?"100%":"60%"}}
            alt=""
            loading="lazy"
          />
          <p>注 1：不同批次之间同一组分不可以相互替换。</p>
          <p>
            注
            2：另需设备：高速冷冻离心机，涡旋混匀仪，移液器，掌上离心机，PCR仪。
          </p>
          <p>注 3：另需耗材：200 µL PCR管或八连排管，PCR管架。</p>
          <p>注 4：96孔板使用前必须离心。</p>
          <p>注 5：本试剂盒产物适用的数据量为1 G。</p>
        </div>
      </div>
      <div className="Sample_requirements">
        <div className="Sample_requirements_child">
          <p>3、样本要求</p>
          <p>
            样本类型：基因编辑后的样本DNA。样本保存和运输：提取的DNA可立即用于本试剂盒处理。或置于(-20±5)℃保存。
          </p>
        </div>
      </div>
      <div className="test_method">
        <div className="test_method_child">
          <p className="test_title">4、检验方法</p>
          <img src={require("../../assets/img/图片87.png")} style={{width:size.width<=750?"90%":""}} alt="" loading="lazy" />
          <p className="img_p">Hi-TOM流程图</p>
          <img src={require("../../assets/img/图片88.png")} style={{width:size.width<=750?"90%":""}} alt="" loading="lazy" />
          <p className="img_p">样品排布图</p>
        </div>
      </div>
      <div className="operating_procedure">
        <div className="operating_procedure_child">
          <p className="operating_title">5、操作步骤</p>
          <p className="operating_title">5.1 PCR引物设计</p>
          <p className="operating_title">
            靶位点需要在离左引物或者右引物的10-100bp范围内。扩增片段大小范围为200-250bp。
          </p>
          <p className="operating_title">
            正向特异引物 Primer F 5’端加正向搭桥序列 5’-
            <span style={{ color: "Red" }}>ggagtgagtacggtgtgc</span> -3’
          </p>
          <p className="operating_title">
            反向特异引物 Primer R 5’端加反向搭桥序列 5’-
            <span style={{ color: "green" }}>gagttggatgctggatgg</span> -3’
          </p>
          <img src={require('../../assets/img/图片89.png')}  style={{width:size.width<=750?"100%":"80%"}} alt="" loading="lazy" />
          <p className="operating_title">
          Primer F: 5’- <span style={{color:"red"}}>ggagtgagtacggtgtgc</span>
          <span style={{color:"purple"}}>gtgaacgatgcgggtggcgcgctg</span>-3’
          </p>
          <p className="operating_title">
          Primer R: 5’- <span style={{color:"green"}}>gagttggatgctggatgg</span>
          <span style={{color:"purple"}}>acacacttacccatcctctcctct</span>-3’
          </p>
          <p className="operating_title">注dddd：黄色高亮标明是靶点序列，绿色高亮标明的是 PAM 序列，紫色高亮标明的 PCR 特异引物序列部分。</p>
          <p style={{
            width:"100%",
            height:"3vh"
          }}></p>
          <p className="operating_title">5.2第一轮 PCR 反应扩增目的片段</p>
          <p className="operating_title">建议：先用野生型DNA为样本，进行梯度PCR，寻找合适的退火温度，能扩增出单一的PCR 产物。PCR 反应参考体系（可根据自己的实验需求调整）：</p>
          <img src={require('../../assets/img/图片90.png')}  style={{width:size.width<=750?"90%":"50%"}} alt="" loading='lazy' />
          <p className="operating_title">PCR 程序：</p>
          <img src={require('../../assets/img/图片91.png')}  style={{width:size.width<=750?"90%":"35%"}} alt="" loading='lazy' />
          <p className="operating_title">PCR 结束后取 3-5µL琼脂糖凝胶电泳检测PCR产物，确保目标产物存在且特异性良好。</p>
          <p style={{
            width:"100%",
            height:"3vh"
          }}></p>
          <p className="operating_title">5.3 用试剂盒进行第二轮 PCR 反应</p>
          <p className="operating_title">5.3.1 从试剂盒中取出Hi-TOM Mix，待完全融化后离心，离心后的Hi-TOM Mix放到实验台上准备加样，加样时建议使用排枪，省力且防止混样。</p>
          <p style={{
            width:"100%",
            height:"3vh"
          }}></p>
           <p className="operating_title">5.3.2 每孔 PCR 反应体系如下：</p>
          <img src={require('../../assets/img/图片92.png')}  style={{width:size.width<=750?"90%":"50%"}} alt="" loading='lazy' />
          <p className="operating_title">注：加样时用排枪吹吸混匀样本，离心后放到 PCR 仪上。</p>
          <p style={{
            width:"100%",
            height:"3vh"
          }}></p>
           <p className="operating_title">5.3.3 PCR反应程序：</p>
          <img src={require('../../assets/img/图片93.png')}  style={{width:size.width<=750?"90%":"30%"}} alt="" loading='lazy' />
          <p className="operating_title">PCR结束后取 3~5 µL琼脂糖凝胶电泳检测PCR产物。</p>
          <p style={{
            width:"100%",
            height:"3vh"
          }}></p>
          <p className="operating_title">5.3.4 PCR产物送样测序</p>
          <p className="operating_title">将扩增完毕的第二轮PCR产物混合（可以用排枪把不同行的样品先混在第一行，再将这几个孔里的样品混合，节省工作量），混好的PCR产物保存于(-20±5)℃（建议取混合后的PCR 产物200 µL，进行琼脂糖凝胶电泳，切胶回收纯化，以免样本中杂质过多而影响文库质量），干冰密封运输，寄回公司进行测序。</p>
          <p className="operating_title">高通量测序样本要求：送样文库浓度需大于0.8 ng/µL，总量大于30 ng，且送样体积不能小于10 µL。不同靶位点样品混在一起送样，测序1G数据。</p>
          <p className="operating_title">每次实验结束及时记录Hi-TOM样品信息单（试剂盒到货后联系销售人员，发送电子版
Hi-TOM 样品信息单），以免遗忘。送样时需要将样品信息单填写后同样本一并邮寄。</p>
          <p className="operating_title">该方法适用于检测基因编辑后样本编辑位点情况及普通变异检测情况，二倍体、多倍体有参物种均可使用，不适用于无参物种。第二轮PCR产物比第一轮PCR产物长100 bp左右。</p>
          <p style={{
            width:"100%",
            height:"3vh"
          }}></p>
          <p className="operating_title">参考文献：Liu Q, Wang C, Jiao X, et al. Hi-TOM: a platform for high-throughput tracking of mutations induced by CRISPR/Cas systems. Sci China Life Sci. 2019;62(1):1-7. doi:10.1007/s11427-018-9402-9</p>
          <p style={{
            width:"100%",
            height:"3vh"
          }}></p>
        </div>
      </div>
    </div>
  )
}

export default DetectionIndex
