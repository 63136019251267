import React from 'react'
import './transformation.model.css'
function TransformationIndex() {
  return (
    <div className='transformation_app'>
      工程菌改造
    </div>
  )
}

export default TransformationIndex
