import React, { useEffect, useState } from "react";
import "./pdf.model.css";

import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button } from "antd";
import {ReactComponent as BackIcon} from './back.svg'
function Pdf() {



  const names=useLocation()
  const navtor=useNavigate()
  


  return <div className="pdf_app">
  
    <Button onClick={()=>{navtor(-1)}} style={{zIndex:"99",position:"absolute",right:"9%",top:"1%",background:"rgba(0,0,0,0)",color:"white",border:"red",display:"flex",alignItems:"center"}}><BackIcon  fontSize={12} width={15} height={15} /><span style={{color:"Red"}}>返回</span></Button>
    <iframe style={{position:"relative"}} src={names.search.split('=')[1]} frameborder="0" width={100+'%'} height={95+'%'}></iframe>
  </div>;
}
export default Pdf;
