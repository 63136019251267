import React from 'react'
import './Investor.model.css'
function InvestorIndex() {
  return (
    <div className='investor_app'>
        <div className='investor_title'></div>
    </div>
  )
}

export default InvestorIndex
