import React from 'react'
import "./patent.model.css"
function PatentIndex() {
  return (
    <div className='patent_app'>
      <div className='patent_title'></div>
    </div>
  )
}

export default PatentIndex
