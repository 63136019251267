import React, { useCallback, useEffect, useState } from 'react'
import "./variation.model.css"
function VariationIndex() {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <div className='variation_app'>
      <div className='variation_title'>
        <h1>ATGCbox</h1>
      </div>
      <div className='varitation_content'>
        <div className='varitation_content_child'>
          <p>自2003年首次对人类基因组进行测序以来，基因组测序成本一直在下降，已经从30多亿美元降到现在的100美元，成本下降3000万倍。下降的速度远快于根据摩尔定律所预测的速度。测序所需时间也从数年降到了一天。测序技术的进步引发了基因组数据的爆炸式增长，测序数据总量大约每七个月增加一倍。到2025年基因组学产生的数据量会超过其他大数据源（短视频和天文学领域）数据量总和的10倍以上，达到两位数的艾字节。因此在分子诊断、临床医疗、药物研发、动植物研究和生物育种等领域，测序数据的二级分析需求越来越多，对软件运算速度和高性能计算资源的要求越来越高。</p>
          <p>ATGCbox是一款基于CUDA+GPU加速的基因数据处理工具包，提供完全与行业标准的GATK4 best practice流程一致的结果，包括BWA-MEM、BQSR、HaplotypeCaller和DeepVariant以及Mutect2的工作流，基于CUDA+GPU计算提高了软件的运行效率。与GATK4 best practice相比，从FASTQ到VCF的计算速度提高了60倍以上，30X的人类全基因组数据只需要20min。同时无需客户搭建本地化超算平台和运行环境，是基因组二、三级分析的最佳解决方案，允许用户选择要运行的步骤、参数设置和流程版本。</p>
        </div>
      </div>
      <div className='img_div'>
          <div className='img_div_child'>
            <img src={require('../../assets/img/图片85.png')} style={{width:size.width<=750?"100%":""}} alt="" loading="lazy" />
            <p>图 ATGCbox工作流</p>
          </div>
      </div>
    </div>
  )
}

export default VariationIndex
