import React from 'react'
import './tema.model.css'
function TemaIndex() {
  return (
    <div className='tema_app'>
        团队
    </div>
  )
}

export default TemaIndex
