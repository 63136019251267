import React from 'react'
import "./chip.model.css"
function ChipIndex() {
  return (
    <div className='chip_app'>
        <div className='chip_title'>
          <h1>基因分型芯片开发</h1>
        </div>
        <div className='chip_content'>
          <div className='chip_child'>
            <p className='chip_content_p'>功能基因或其他重要位点的分型芯片，可以广泛应用于动植物品种快速选育以及人类医学的疾病检测。通过对前期对群体遗传功能位点挖掘、QTL定位和全基因组关联分析等研究，并通过实验进行功能位点验证，研究者们会获得大量具有应用价值的功能位点，因此需要利用多重PCR和靶向捕获等技术对其进行分型。功能位点分型芯片具有检测周期短、位点数目灵活、极低价格和极高准确性等优势。在动植物的品种快速选育中，功能位点分型芯片能够进行优良个体精准选育、种质资源开发保护、品种鉴定、指纹图谱构建和分子身份证等。</p>
            <p className='chip_content_p'>百奥华兴提供群体样本筛选、高通量测序分析以及分型芯片开发检测全链条服务。其中在分析过程中，为了保证位点准确性，会利用品种鉴定、功能基因挖掘、群体进化、全基因组关联分析、全基因组选择分析、BSA混池测序分析、指纹图谱构建、eQTL、TWAS和遗传图谱等技术对重要功能位点进行挖掘，并通过多组学大数据联合分析，利用机器学习和深度学习等算法对重要位点进行筛选。同时将公开研究的与关注性状基因上的SNP位点和新研究发现的致因位点包含到候选位点中，比如己知功能SNP位点等。最终，对候选的SNP位点进行位点的打分和筛选，最后选定的位点进行分型芯片设计。对芯片进行验证阶段，收集相关品种资源或者杂交后代进行分型验证，从而形成最终的极高精度的应用芯片。</p>
            <h3 className='content_title'>两种分型技术原理</h3>
            <p className='chip_content_p'>ARMS-PCR：等位基因特异性PCR（allele-specific PCR），也称为扩增阻碍突变系统（amplification refractory mutation system, ARMS），用于对已知突变基因进行检测。常规PCR扩增DNA所用的上、下游引物与靶序列完全匹配，而ARMS PCR采用等位基因特异的两条上游引物，两者在3’端核苷酸不同，一个对野生型等位基因特异，另一个对突变型等位基因特异，在Taq DNA聚合酶作用下，与模板不完全匹配的上游引物将不能退火，不能生成PCR产物，而与模板匹配的引物体系则可扩增出产物，通过毛细管电泳检测能很容易地分辨出扩增产物的有无，从而确定SNP基因型。</p>
            <img src={require('../../assets/img/图片98.png')} alt="" loading="lazy" />
            <img src={require('../../assets/img/图片99.png')} alt="" loading="lazy" />
            <p className='img_p'>图 ARMS-PCR技术原理</p>
            <p className='chip_content_p'>SNaPshot技术，是由美国应用生物公司（ABI）开发，基于荧光标记单碱基延伸原理的分型技术，也称小测序。适用中等通量的SNP分型项目。核心反应体系中包含：测序酶；四种荧光标记ddNTP；紧挨多态位点5’-端的不同长度延伸引物；包含SNP位点的PCR产物模板。通过反应，引物延伸一个碱基即终止，再经测序仪检测，根据峰的移动位置确定。该延伸产物对应的SNP位点，根据峰的颜色可得知掺入的碱基种类，从而确定该样本的基因型。由于可以利用多重PCR反应体系，因此可以同时检测多种SNP位点，从而达到极低成本的目的。</p>
            <img src={require('../../assets/img/图片100.png')} alt="" loading="lazy" />
            <p className='img_p'>图 SNaPshot技术原理</p>
            <p className='chip_content_p'>百奥华兴团队拥有丰富的群体高通量测序分析和功能位点开发经验，已发表多篇高分文章和专利，与畜禽、农作物和中草药的育种科学家们一起设计近十款功能位点分型芯片，常见的物种有鸡鸭鹅、酸枣仁、防风、半夏、石菖蒲和人参属等，助力个体精准选育。</p>
          </div>
        </div>
    </div>
  )
}

export default ChipIndex
