import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './configureStore';
import {Provider} from "react-redux"
import {BrowserRouter,HashRouter} from "react-router-dom"
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
     
      <HashRouter>
       <App />
      </HashRouter>
     
    </Provider>

    
 
);


reportWebVitals();
