import React, { useCallback, useEffect, useState } from "react";
import "./pacbio.model.css";
function PacBioIndex() {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <div className="pacbio_app">
      <div className="title_div">
        <h1>三代建库测序服务</h1>
      </div>
      <div className="pac_div" style={{height:size.width<=750?"15vh":""}}>
        <div className="pac_child">
          <div className="pac_title">
            <h3>简介</h3>
            <p>
              三代建库测序产品是对客户提供的合格样品进行提取建库并运用PacBio
              Revio平台或PromethION平台进行测序，产出高质量的测序数据提供给客户。
            </p>
          </div>
        </div>
      </div>
      <div className="pacbio_revio">
        <div className="pacbio_child">
          <h3 className="pacbio_title">一、Pacbio Revio平台</h3>
          <div className="SMRT_principle">
            <p style={{ fontWeight: "bold" }}>1.1 SMRT 技术原理</p>
            <p>
              单分子实时测序（Single Molecule Real-Time,
              SMRT）技术建立在两项革命性的发明基础之上，从而克服了测序领域的重大挑战。第一，零模波导孔技术（Zero-Mode
              Waveguides，ZMWs）让光只能照亮固定了单个DNA
              聚合酶／模板分子的纳米孔底部。第二，磷酸化的核苷酸可帮助固定的DNA聚合酶完成一个全天然的DNA链合成过程。
            </p>
          </div>
          <div className="SMRT_principle">
            <p style={{ fontWeight: "bold" }}>1.2 SMRT 测序的优势</p>
            <p>
              • 长读长 • 超高的准确性 • 均一的覆盖度 • 同步的表观遗传学信息识别
            </p>
          </div>
          <div className="Service_process">
            <p style={{ fontWeight: "bold" }}>1.3 服务流程</p>
            <div className="process_img">
              <dl>
                <dd>文库制备</dd>
                <dt style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                  <img
                    src={require("../../assets/img/图片5.png")}
                    width={70+'%'}
                    height={70+'%'}
                    alt=""
                    loading="lazy"
                  />
                </dt>
                <dd>
                  <p>DNA Template Kit</p>
                  <p>DNA Polymerase Kit</p>
                  <p>MagBead Kit</p>
                </dd>
              </dl>
              <dl>
                <dd>上机测序</dd>
                <dt style={{ display:"flex",
                      alignItems:"center",
                      justifyContent:"center"}}>
                  <img
                    src={require("../../assets/img/图片6.png")}
                    width={50 + "%"}
                    height={50 + "%"}
                    style={{
                      width:size.width<=750?"50%":"",
                      height:size.width<=750?"50%":"",
                     
                    }}
                    alt=""
                    loading="lazy"
                  />
                </dt>
                <dd>
                  <p>PacBio Revio/ONT </p>
                  <p>Sequencing Platform</p>
                </dd>
              </dl>
              <dl>
                <dd>数据分析</dd>
                <dt style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                  <img
                    src={require("../../assets/img/图片7.png")}
                    width={85+'%'}
                    height={85+'%'}
                    alt=""
                    loading="lazy"
                  />
                </dt>
                <dd>
                  <p>SMRT Analysis SMRT </p>
                  <p>Portal SMRT View</p>
                </dd>
              </dl>
            </div>
          </div>
          <div className="service_context">
            <p style={{ fontWeight: "bold" }}>1.4 服务内容:</p>
            <table>
              <thead>
                <tr>
                  <td>测序平台</td>
                  <td>测序策略</td>
                  <td>文库类型</td>
                  <td>数据产出</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>PacBio Revio</td>
                  <td>SMATR</td>
                  <td>10-20K 大片段文库</td>
                  <td>90G HiFi data/cell</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="Prome_div" style={{
                 
                  height:size.width<=750?"120vh":""
                }}>
        <div className="prome_child">
          <h3 className="prome_title">二、PromethION 平台</h3>
          <div className="ONT_principle">
            <p style={{ fontWeight: "bold" }}>2.1 ONT 技术原理</p>
            <p>
              纳米孔测序是借助电泳的原理，DNA/RNA
              链在马达蛋白的引导下与嵌在薄膜上的纳米孔通道蛋白结合，解旋的同时在生物膜两侧电势差的作用下，以
              450bp/s 的速度控制 DNA/RNA
              链通过纳米孔通道蛋白。由于不同碱基带电性质不同，对纳米孔中电流的扰动情况也有差别，通过实时检测电信号的差异就能检测出通过的碱基类别，从而实现测序。
            </p>
          </div>
          <div className="ONT_advantage">
            <p style={{ fontWeight: "bold" }}>2.2 ONT 测序优势</p>
            <p>• 微量建库 • 直接测序 • 极限读长 • 高准确率 • 高通量 • 低价格</p>
            <div className="ONT_img">
              <div className="ONT_img_list">
                <p>Maximum throughput and Long reads length</p>
                <img src={require("../../assets/img/图片8.png")} style={{
                  width:size.width<=750?"100%":"",
                  height:size.width<=750?"100%":""
                }} alt="" loading="lazy" />
                <p>
                  高通量：图 A 为 MinION 芯片的产出情况，PromethION
                  芯片纳米孔数约为 MinION 芯片的 5 倍，Ligation 1D 文库产出可达
                  60G。
                </p>
                <p>
                  极限读长：图 B、C、D 分别展示了不打断、g-TUBE
                  打断及片段筛选后的读长情况，目前已观测到的 ONT 测序最长读长达
                  2M。
                </p>
              </div>
              <div className="ONT_img_list">
                <p>High Consensus Accuracy</p>
                <img src={require("../../assets/img/图片9.png")} style={{
                  width:size.width<=750?"100%":"",
                  height:size.width<=750?"50%":""
                }}  alt="" loading="lazy" />
                <p>高一致准确性：准确性可以达到 99.9%。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pac_Service_process">
        <div className="pac_service_process_child">
          <p style={{ fontWeight: "bold" }}>2.3 服务流程</p>
          <div className="pac_server_img_list">
            <div>
              <p>文库制备</p>
              <img src={require("../../assets/img/图片10.png")} alt="" loading="lazy" />
              <p
                style={{
                  width: "100%",
                  height: "20%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                Ligation Sequencing Kit 1D
              </p>
            </div>
            <div>
              <p>上机测序</p>
              <img src={require("../../assets/img/图片11.png")} alt="" loading="lazy" />
              <p
                style={{
                  width: "100%",
                  height: "20%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span>PromethION</span> <span>PromethION flow cell</span>{" "}
                <span>Priming Kit</span>
              </p>
            </div>
            <div>
              <p>数据分析</p>
              <img src={require("../../assets/img/图片12.png")} alt="" loading="lazy" />
              <p
                style={{
                  width: "100%",
                  height: "20%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span>PromethION</span>
                <span> Software</span>
                <span> Dogfish Guppy</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="service_context_div">
        <div className="service_context_child">
          <p style={{ fontWeight: "bold" }}>2.4 服务内容</p>
          <table style={{width:size.width<=750?"95%":"",fontSize:size.width<=750?"0.75rem":""}}>
            <thead>
              <tr>
                <td>测序平台</td>
                <td>文库类型</td>
                <td>数据产出</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PromethION</td>
                <td>Ligation 1D 文库</td>
                <td>≥60G/Cell</td>
              </tr>
              <tr>
                <td>PromethION</td>
                <td>Ligation 1D ultra——long 文库</td>
                <td>≥30G/Cell</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="my_advantage">
        <div className="my_advantage_child">
          <p style={{ fontWeight: "bold" }}>我们的优势</p>
          <p  style={{textIndent:"4rem",margin:'0.625rem 0'}}>
            十多年的三代建库测序项目服务经验，针对不同样本类型，进行细分优化，成功交付各种物种的项目，专业的团队为您的建库测序项目稳定运行交付保驾护航；
          </p>
          <p style={{textIndent:"4rem",margin:'0.625rem 0'}}>
            拥有Pacbio测序合作平台，以及高通量的ONT测序平台—— PromethION
            48保证客户项目周期和数据质量，为客户提供稳定快速和高质量的测序服务。
          </p>
        </div>
      </div>
    </div>
  );
}

export default PacBioIndex;
