const itemFive = [
  {
    key: "1",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        target=""
        rel="noopener noreferrer"
        href="#/Biohuaxing/present/"
      >
        简介
      </a>
    ),
  },
  {
    key: "3",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        target=""
        rel="nooper noreferrer"
        href=""
      >
        技术平台
      </a>
    ),
  },
  {
    key: "4",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        target=""
        rel="nooper noreferrer"
        href=""
      >
        研究成果
      </a>
    ),
  },
  {
    key: "5",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        target=""
        rel="nooper noreferrer"
        href=""
      >
        投资者关系
      </a>
    ),
  },
  {
    key: "6",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        target=""
        rel="nooper noreferrer"
        href=""
      >
        资质专利
      </a>
    ),
  },
  {
    key: "7",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        target=""
        rel="nooper noreferrer"
        href=""
      >
        新闻中心
      </a>
    ),
  },
  {
    key: "8",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        target=""
        rel="nooper noreferrer"
        href=""
      >
        联系我们
      </a>
    ),
  },
];
export default itemFive;
