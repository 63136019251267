const initialState = [];

const tags = (state = initialState, { type, payload }) => {
  switch (type) {
    case "set_tags": {
      return payload;
    }
    default:
      return state;
  }
};
export default tags;
