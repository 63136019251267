import React, { useEffect } from "react";
import { Layout, Flex } from "antd";
import HeadIndex from "./components/Head/index";
import FooterIndex from "./components/Foot";
import { Outlet } from "react-router-dom";
const { Header, Footer, Sider, Content } = Layout;
const app = {
  width: "100vw",
  height: "100vh",

};
const headerStyle = {
  width: "100vw",
  height: "5rem",
  position: "fixed",
  top: "0",
  left: "0",
  zIndex: "99",
};
const contentStyle = {
  width:"100%",
  marginTop: "5rem",
};
const footerStyle = {
  width: "100vw",
  backgroundColor: "#ECEAEA",
};

function MainLayOut(props) {

  return (
    <div className="LayOut" style={app}>
      <div className="header" style={headerStyle}>
        <HeadIndex />
      </div>
      <div className="section" style={contentStyle}>
        <Outlet/>
      </div>
      <div className="footer" style={footerStyle}>
        <FooterIndex />
        
      </div>
    </div>
  );
}

export default MainLayOut;
