import React, { useCallback, useEffect, useState } from "react";
import "./Genomic.model.css";
function GenomicIndex() {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <div className="Genomic_app">
      <div className="Genomic_title">
        <h1 className="title_text"> T2T基因组测序组装</h1>
      </div>
      <div className="Product_presentation" style={{height:size.width<=750?"100vh":"55vh"}}>
        <div className="Product_presentation_child">
          <div className="pres_child_top">
            <p>一、产品概述</p>
            <p style={{height:size.width<=750?"100%":"85%"}}>
              从头测序即de
              novo测序，不需要任何参考序列资料即可对某个物种进行测序，用生物信息学分析方法进行拼接、组装，从而获得该物种的基因组序列图谱。利用全基因组从头测序技术，可以获得动植物的全基因组序列，带动这个物种下游一系列研究的开展，从而推进该物种的研究。全基因组序列图谱完成后，可以构建该物种的基因组数据库，为该物种的后基因组学研究搭建一个高效的平台，为后续的基因挖掘、功能验证提供DNA序列信息。我们结合短读长和长读长测序技术，可以高效、低成本地完成所有物种的基因组序列图谱，尤其是动植物基T2T（Telomere-to-telomere）基因组测序和组装。
            </p>
          </div>
          <div className="pres_child_bottom" style={{alignItems:size.width<=750?"flex-end":""}}>
            <p>二、产品优势</p>
            <div className="pres_child_bottom_item">
              <img src={require("../../assets/img/图片13.png")} alt="" loading="lazy" />
              <p>
                <span>提取质量好</span>
                <span>丰富的长片段提取经验，提取DNA质量高，提取成功率高</span>
              </p>
            </div>
            <div className="pres_child_bottom_item">
              <img src={require("../../assets/img/图片14.png")} alt="" loading="lazy" />
              <p>
                <span>准确度和超长兼顾</span>
                <span>
                  基于CCS高精度结合ONT Ultra
                  long测序数据，组装结果完整性和连续性高
                </span>
              </p>
            </div>
            <div className="pres_child_bottom_item">
              <img src={require("../../assets/img/图片15.png")} alt="" loading="lazy" />
              <p>
                <span>分析经验多</span>
                <span>
                  分析经验丰富，已成功组装多类物种，为项目顺利交付保驾护航
                </span>
              </p>
            </div>
            <div className="pres_child_bottom_item">
              <img src={require("../../assets/img/图片16.png")} alt=""  loading="lazy" />
              <p>
                <span>平台多样</span>
                <span>多平台联合应用保证超高测序通量，可实现快速交付</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Product_App"  style={{height:size.width<=750?"35vh":""}} >
        <div className="product_app_child">
          <p style={{fontSize:"0.9375rem"}}>三、产品应用</p>
          <div className="product_app_img" style={{flexWrap:size.width<=750?"wrap":""}}>
            <img src={require("../../assets/img/图片17.png")} width={size.width<=750?"48%":""} alt="" loading="lazy" />
            <img src={require("../../assets/img/图片18.png")} width={size.width<=750?"48%":""} alt="" loading="lazy" />
            <img src={require("../../assets/img/图片19.png")} width={size.width<=750?"48%":""} alt="" loading="lazy" />
            <img src={require("../../assets/img/图片20.png")} width={size.width<=750?"48%":""} alt="" loading="lazy" />
          </div>
        </div>
      </div>
      <div className="Technical_process" style={{marginTop:size.width<=750?"100px":""}}>
        <div className="Technical_process_child">
          <p>四、技术流程</p>
          <img src={require("../../assets/img/图片21.png")} 
          style={{
            width:size.width<=750?"100%":"",
            height:size.width<=750?"50%":""
          }}
          alt="" loading="lazy" />
        </div>
      </div>
      <div className="technical_parameter" style={{height:size.width<=750?"169vh":"",marginTop:size.width<=750?"-4.25rem":""}}>
        <div className="technical_parameter_child">
          <p>五、技术参数</p>
          <div className="top_param">
            <p>5.1 样本要求</p>
            <div className="top_table">
              <p className="top_table_title">表1 各个平台DNA送样要求</p>
              <table>
                <thead>
                  <tr>
                    <td>测序平台</td>
                    <td>文库类型</td>
                    <td>样品类型</td>
                    <td>样品浓度</td>
                    <td>样本量</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Nanopore</td>
                    <td>20-100Kb</td>
                    <td rowSpan={2}>基因组DNA</td>
                    <td>20ng/μl</td>
                    <td>10μg</td>
                  </tr>
                  <tr>
                    <td>PacBio</td>
                    <td>20Kb</td>
                    <td>20ng/μl</td>
                    <td>10μg</td>
                  </tr>
                  <tr>
                    <td>DNBSEQ</td>
                    <td>20-100Kb</td>
                    <td></td>
                    <td>20ng/μl</td>
                    <td>1.5μg</td>
                  </tr>
                  <tr>
                    <td>Hi-C</td>
                    <td>--</td>
                    <td>完成甲醛交联的DNA/新鲜血液/活体组织</td>
                    <td>--</td>
                    <td>--</td>
                  </tr>
                </tbody>
              </table>
              <p className="pay_attention_to">
                注：大片段文库不建议客户送DNA样本，建议直接送组织：组织样本需求量依据不同物种及组织样本类型而不同，如有需要请咨询客户经理。
              </p>
            </div>
          </div>
          <div className="bottom_param">
            <p>5.2 测序策略</p>
            <div className="bottom_table">
              <p className="bottom_table_title">
                表2 动植物de novo各个平台的测序策略（T2T基因组）
              </p>
              <table>
                <thead>
                  <tr>
                    <td>测序平台</td>
                    <td>文库大小</td>
                    <td>测序读长</td>
                    <td>推荐测序深度</td>
                    <td>主要用途</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Nanopore</td>
                    <td>50Kb或者100Kb</td>
                    <td>30Kb及以上</td>
                    <td>100X</td>
                    <td>组装</td>
                  </tr>
                  <tr>
                    <td>PacBio</td>
                    <td>20Kb及以上</td>
                    <td>10Kb及以上</td>
                    <td>30X</td>
                    <td>组装</td>
                  </tr>
                  <tr>
                    <td>DNBSEQ/Illumina</td>
                    <td>350bp</td>
                    <td>PE150</td>
                    <td>50X</td>
                    <td>Survey/纠错/评估</td>
                  </tr>
                  <tr>
                    <td>Hi-C</td>
                    <td>250-450bp</td>
                    <td>PE150</td>
                    <td>100X</td>
                    <td>Hi-C辅助组装定位</td>
                  </tr>
                  <tr>
                    <td>RNA-seq</td>
                    <td>350bp</td>
                    <td>PE150</td>
                    <td>6G</td>
                    <td>基因组注释</td>
                  </tr>
                </tbody>
              </table>
              <p className="pay_attention_to">
                注：上文提及的组装手段可根据物种特性及技术特点进行选择组合。
              </p>
            </div>
          </div>
          <div className="life_cycle">
            <p>项目周期：</p>
            <p>
              动植物：简单基因组2个月，复杂基因组3个月，超大基因组周期另议。
            </p>
          </div>
        </div>
      </div>
      <div className="Case_analysis" style={{height:size.width<=750?"331vh":"",marginTop:size.width<=750?"-5rem":""}}>
        <div className="Case_analysis_child">
          <p>六、案例解析</p>
          <div className="demo_journal" style={{height:size.width<=750?"":"5%"}}>
            <p>案例：拟南芥着丝粒的遗传和表观遗传景观</p>
            <p><span>发表期刊：Science</span> <span>组装策略：ONT+HiFi+HiC</span></p>
          </div>
          <div className="research_results">
            <p>研究结果</p>
            <p className="context_item">
              <span>1. 拟南芥基因组着丝粒区域的完整组装</span>
              <span>
                该研究结合HiFi和ONT
                Ultra-long三代测序数据及Hi-C数据，组装了拟南芥Col-CEN基因组。其中3条染色体的端粒被完全组装，达到T2T级别。第2和4号染色体除了短臂45S
                rDNA和邻近的端粒外的所有区域均被完整组装。通过与TAIR10版本基因组比较，新组装的基因组获得了5个着丝粒区域以及2个NORs区域和8个染色体端粒区域。着丝粒的特征是178bp的高度重复序列，利用荧光原位杂交确认了着丝粒附近的重复簇，并用Col-CEN
                FISH探针从细胞遗传学角度提供着丝粒完整鉴定的证据。
              </span>
            </p>
            <p className="context_item">
              <span>2. 拟南芥CEN180重复特征</span>
              <span>
                通过对基因组上着丝粒CEN180序列的检索，共鉴定到66,131个CEN180序列，单条染色体上的拷贝数从11,848到15,613个不等。不同染色体间仅有0.3%的CEN180序列是完全相同的，而同一条染色体内57.1%
                到69.0%的CEN180都具有多个拷贝，说明不同染色体CEN180序列存在明显的特异性，而同一条染色体内的CEN180序列存在均质化的趋势。从全基因组水平来看，序列变异幅度越小的CEN180上CENH3越富集，CENH3的富集程度与CEN180序列变异距离之间呈现明显的负相关性。同时拷贝数越多的CEN180区域CENH3的富集也越明显。并伴随着更高的CG
                DNA甲基化。
              </span>
            </p>
            <p className="context_item">
              <span>3、ATHILA逆转录转座子对拟南芥着丝粒的插入</span>
              <span>
                5号染色体着丝粒区域CEN180拷贝数较低，并且着丝粒的CEN180间隔区域存在ATHILA逆转录转座子。ATHILA的H3K9me2修饰和CHG甲基化程度较高，CEN180卫星序列和ATHILA反转座因子具有不同的表观遗传特征。使用FISH对着丝粒进行细胞学验证，表明ATHILA的插入影响了拟南芥CEN180的遗传和表观遗传特征。
              </span>
            </p>
            <p className="context_item">
              <span>4. 着丝粒内的表观遗传</span>
              <span>
              着丝粒区域CG含量偏高，基因的密度也随着靠近着丝粒而降低。相反，转座子的密度随之增加，但是随着CEN180增加，转座子的密度在着丝粒中心存在下降的趋势。H3K4me3修饰在着丝粒区域含量降低，H3K9me2则相反。着丝粒区域的non-CG甲基化依赖于CG甲基化。相比于着丝粒邻近区域，着丝粒上常染色质修饰增加，异染色质修饰相对降低。说明着丝粒与邻近区域共同组成了一个混合的染色质状态。
              </span>
              <span style={{textIndent:"3.5rem"}}>减数分裂的重组导致染色体交换或者基因转换可以促进着丝粒的进化，虽然在着丝粒区域同源染色体交换被抑制，但研究者在着丝粒区域检测到了减数分裂DNA双链断裂的产生，说明该区域减数分裂重组可以起始，提供了重组促进着丝粒进化的证据。研究显示，着丝粒GC含量偏高，伴有大量的CG、CGH和CHH甲基化，着丝粒发生交换的水平很低，但是在CG甲基化突变体met1-3中交换显著提高。表明DNA甲基化可以影响减数分裂时着丝粒的重组交换。</span>
            </p>
            <p style={{width:"100%",height:size.width<=750?"":"40%",display:"flex",alignItems:"center",justifyContent:"center"}}>
            <img src={require('../../assets/img/图片22.png')} style={{margin:"auto",width:size.width<=750?"100%":"",height:size.width<=750?"50%":"100%"}} alt="" loading="lazy" />
            </p>
          <div className="references_div" style={{marginTop:size.width<=750?"0":"5.375rem"}}>参考文献：Naish M, Alonge M, Wlodzimierz P, et al. The genetic and epigenetic landscape of the Arabidopsis centromeres. Science. 2021;374(6569):eabi7489. doi:10.1126/science.abi7489.</div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default GenomicIndex;
