import React, { useCallback, useEffect, useState } from 'react'
import "./newtext.model.css"
import newList from  "../../assets/Json/newscenter"
import TextIndex from '../TextIndex/text'
import TextIndexTwo from '../TextIndexTwo'
function NewsText(props) {
    const {id}=props
    const [newtext,setNewText]=useState({});
    
    useEffect(()=>{
        setNewText(newList[id])
    },[])   
    const [size, setSize] = useState({
        width: document.documentElement.clientWidth,
        hieght: document.documentElement.clientHeight,
      });
      const onResize = useCallback(() => {
        setSize({
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight,
        });
      }, []);
      useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
          window.removeEventListener("resize", onResize);
        };
      }, []);
    // 判断id 根据id判断该展示什么新闻
    const IsText=()=>{
        if(id==='0'){
            return <TextIndex/>
        }
        if(id==='1'){
            return <TextIndexTwo/>
        }
    }
  return (
    <div className='news_text'>
        <div className='news_text_title'>
            <h2  style={{fontSize:size.width<=750?"15px":"",textAlign:size.width<=750?"center":""}}>{newtext.title}</h2>
        </div>
        <div className='time_div'>
           <div className="time_div_child">
           <p>{newList[id].time}</p>
           <p>编辑：百奥华兴</p>
           </div>
        </div>
        {
            IsText()
        }  
    </div>
  )
}

export default NewsText