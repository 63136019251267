import React, { useCallback, useEffect, useState } from 'react'
import './excavate.model.css'
import { ReactComponent as RightIcon } from "./彩色-定位-向右.svg";

function ExcavateIndex() {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <div className="Multi_app">
    <div className="multi_title">
      <h1>多组学大数据功能位点挖掘数据库平台</h1>
    </div>
    <div className="easy_context">
      <p>
        为了克服单一参考基因组在重要性状相关遗传位点和基因挖掘与利用上的困难，科学家提出通过整合物种的代表性种质资源基因组信息，构建群体基因组和多组学数据集，来更加全面的检测物种候选基因相关的遗传变异或者调控序列。利用大规模多组学基因组数据可以获得各个样本的共有和特有的基因集，同时进行多组学数据关联分析，以便研究人员能够快捷、方便地检索和使用物种的相关遗传和性状资源。目前的数据库是以多组学分析平台为核心，包含各组学数据、品种性状记录和常见的生物信息学工具，整合基因组序列查询、基因注释、系统发育关系、基因表达数据、表观调控数据、变异信息、基因通路信息、品种信息和常用多组学分析工具，提供一个快速搜索和关联分析的可视化集成平台。该数据库平台是重要的多组学结果呈现方式。在多组学大数据时代，通过搭建数据库平台，不但可以方便本课题组对基因组、泛基因组以及育种数据等研究成果进行更深入的数据挖掘和功能基因组学研究，同时也方便于其他研究者分享研究成果，提升研究成果的影响力。参考示例如下：
      </p>
      <p>
        杨庆勇老师团队构建出首个综合性的油菜多组学数据库—BnIR，该数据库搜集并整合了甘蓝型油菜基因组、转录组、变异组、表观遗传、表型组和代谢组等6种组学数据。该数据库是油菜领域能够实现类似TAIR的、能够支撑和服务油菜生物学研究的综合性数据库。解决了油菜多组学数据规模的激增，对数据存储、检索和分析提出了更高的需求的问题。同时可以把不同来源、不同组学、不同平台产生的生物大数据进行高效的整合及挖掘。
        该数据库以前期发布的BnPIR、BnTIR以及BnVIR数据库为基础，进一步搜集了近年来新发布的多组学数据集，并进行了统一过滤、处理和分析，最终构建出目前最为系统和全面的油菜多组学数据库-BnIR。该团队共搜集了29个甘蓝型油菜以及近缘物种的基因组、2,791个油菜组织样本的转录组、2,311个样本的群体遗传变异数据、118个表型、266个代谢物含量以及多种表观基因组学数据（包括DNA甲基化、组蛋白修饰、染色质可及性和染色质交互强度等）组成的公共多组学数据。在BnIR数据库中，基于群体多组学数据，研究者利用全基因组关联分析、表达数量性状位点定位、孟德尔随机化和共定位分析等多种分析策略，对不同组学数据进行了关联与整合。相关数据及结果已上传至数据库的多组学模块，以方便用户进行快速检索、分析和利用。此外，BnIR数据库还集成了18种在线分析工具，包括序列比对（BLAST、MSA）、富集分析（GO、KEGG）、关联分析（GWAS、TWAS、eQTL等）、共定位分析（COLOC)、孟德尔随机化分析（SMR）、遗传相似性分析、ePCR、引物设计等。最后研究者以种子硫苷含量和含油量为例，系统地阐述了BnIR在解析遗传变异位点对基因表达和表型影响方面的应用，展现了BnIR的在挖掘重要变异、探索变异作用机制方面的价值和潜力。与其他现有的数据库相比，BnIR为油菜研究者提供了“一站式”的数据检索、获取和挖掘服务。
      </p>
      <div className="easy_img">
        <img src={require('../../assets/img/图片51.png')} style={{width:size.width<=750?"90%":""}} alt="" loading='lazy' />
        <p>图 油菜多组学数据库BnIR的构建流程和功能</p>
        <p>参考文献：Yang Z, Wang S, Wei L, et al. BnIR: A multi-omics database with various tools for Brassica napus research and breeding. Mol Plant. 2023;16(4):775-789. doi:10.1016/j.molp.2023.03.007</p>
      </div>
      <div className="easy_img_two">
        <p>刁现民老师团队发布了谷子最完整的多组学综合数据库，该数据库包含了T2T基因组、泛基因组、大群体变异组、转录组、代谢组、各类群体多环境表型组以及种质材料信息等谷子研究亟需的多组学数据，并开发相关在线工具，为谷子功能基因理论和育种研究提供了最全面的共享平台。谷子在群体遗传以及多组学研究中发展迅速，从2013年916份资源的谷子单倍型解析，到近年的398份谷子籽粒代谢组、转录组、变异组多组学研究，以及2023年大规模泛基因组及群体遗传研究，积累了大量的组学数据。同时，随着谷子株型和旱胁迫相关基因DPY1，光周期属性决定因子SiPHYC，重要产量基因SGD1等功能基因的解析，谷子模式植物体系正走向成熟。为了充分利用这些数据，加快谷子基础研究，该团队构建了谷子第一个真正意义的综合性数据库Setaria-db (www.setariadb.com/millet)。该数据库包含了Yugu1-T2T基因组、110个泛基因组、1844个变异组、312个代谢组和转录组、野生种（A10）、农家种（Ci846）和栽培种（Yugu1）多组织表达谱，618个品种11年13个地点收集的226组表型数据，916份种质材料信息等。并开发相关在线工具（Haplotype, BLAST, CRISPR design, PRIMER design，GO，KEGG, Multi-omics correlation analysis），为谷子功能基因基础理论和育种应用研究提供了重要平台。</p>
        <img src={require('../../assets/img/图片52.png')} style={{width:size.width<=750?"90%":""}} alt="" loading='lazy'  />
        <p>图 谷子综合数据库Setaria-db (www.setariadb.com/millet) 主页  </p>
        <img src={require('../../assets/img/图片53.png')} style={{width:size.width<=750?"90%":""}} alt="" loading='lazy'  />
        <p style={{textAlign:"center"}}>图 谷子综合数据库Setaria-db (www.setariadb.com/millet) 概况</p>
        <p >参考文献：He Q, Wang C, He Q, et al. A complete reference genome assembly for foxtail millet and Setaria-db, a comprehensive database for Setaria. Mol Plant. Published online December 27, 2023. doi:10.1016/j.molp.2023.12.017</p>
      </div>
    </div>
  
    <div className="rapeseed_div">
      <div className="rapeseed_child">
        <p className="rapeseed_title">油菜泛基因组数据库</p>
        <p className="rapeseed_context">
          油菜泛基因组数据库，是一个可以方便油菜育种学家开展研究的种质资源数据库网站。研究者利用illumina、PacBio、HiC、BioNano等测序数据，denovo组装得到8个二倍体甘蓝型油菜精细基因组，并获得大量的PAV、SV、SNP等变异信息，其中某些变异与油菜的开花时间显著相关。研究者通过整合泛基因组、基因组注释、比较基因组、转录组及1689份油菜的重测序数据，构建了首个油菜泛基因组和比较基因组生物信息平台BnPIR(图1),包括PAN
          BROWSER, SEARCH, GBROWSE, TOOLS, PATHWAY, DOWNLOAD等模块。
        </p>
        <div className="img_one">
          <img src={require("../../assets/img/图片37.png")} style={{width:size.width<=750?"90%":""}} alt="" loading='lazy'  />
          <p>图1 油菜泛基因组数据库</p>
        </div>
        <div className="img_two">
          <p>
            <RightIcon
              width={20}
              height={100 + "%"}
              style={{ margin: "0 0.625rem" }}
            />
            <span style={{ color: "#00B050" }}>PAN BROWSER模块</span>
            ——提供泛基因组PAV等研究结果的展示。
          </p>
          <img src={require("../../assets/img/图片38.png")} style={{width:size.width<=750?"90%":""}} alt="" loading='lazy'  />
          <p>图2 油菜泛基因组数据库PAN BROWSER模块</p>
        </div>
        <div className="img_three">
          <p>
            <RightIcon
              width={20}
              height={100 + "%"}
              style={{ margin: "0 0.625rem" }}
            />
            <span style={{ color: "#00B050" }}>SEARCH模块</span>
            ——涵盖了基因注释信息、油菜样本采集信息、基因表达量、NLR基因信息等。
          </p>
          <img src={require("../../assets/img/图片50.png")} style={{width:size.width<=750?"90%":""}} alt="" loading='lazy'  />
          <p>图3 油菜基因注释信息查询</p>
        </div>
        <div className="img_four">
          <img src={require("../../assets/img/图片39.png")} style={{width:size.width<=750?"90%":""}} alt="" loading='lazy'  />
          <p>图4 油菜基因表达量查询</p>
        </div>
        <div className="img_five">
          <p>
            <RightIcon
              width={20}
              height={100 + "%"}
              style={{ margin: "0 0.625rem" }}
            />
            <span style={{ color: "#00B050" }}>GBROWSE模块</span>
            ——包含基因在各基因组的共线性，以及8个基因组的基因组浏览器 (Genome
            browser)。
          </p>
        </div>
        <div className="img_six">
          <p>
            <RightIcon
              width={20}
              height={100 + "%"}
              style={{ margin: "0 0.625rem" }}
            />
            <span style={{ color: "#00B050" }}>TOOLS模块</span>
            ——提供了多个易于研究者进一步深入挖掘的工具，包括基因索引、Blast、功能富集分析、根据基因或者指定物理位置提取目标序列等功能。
            (Genome browser)。
          </p>
          <img src={require("../../assets/img/图片40.png")} style={{width:size.width<=750?"90%":""}} alt="" loading='lazy'  />
          <p>图5 基因索引</p>
          <img src={require("../../assets/img/图片41.png")} style={{width:size.width<=750?"90%":""}} alt="" loading='lazy'  />
          <p>图6 Blast结果</p>
        </div>
        <div className="img_seven">
          <img src={require("../../assets/img/图片42.png")} style={{width:size.width<=750?"90%":""}} alt=""  loading='lazy' />
          <p>图7 富集分析结果</p>
        </div>
        <div className="img_eight">
          <p>
            <RightIcon
              width={20}
              height={100 + "%"}
              style={{ margin: "0 0.625rem" }}
            />
            <span style={{ color: "#00B050" }}>PATHWAY模块</span>
            ——包含ZS11油菜中所有的KEGG通路。
          </p>
          <img src={require("../../assets/img/图片43.png")} style={{width:size.width<=750?"90%":""}} alt="" loading='lazy'  />
          <p> 图8 KEGG pathway</p>
        </div>
        <div className="img_nine">
          <p>
            <RightIcon
              width={20}
              height={100 + "%"}
              style={{ margin: "0 0.625rem" }}
            />
            <span style={{ color: "#00B050" }}>DOWNLOAD模块</span>
            ——提供了8个denovo组装的油菜基因组的基因组fasta、gff、cds、pep和基因功能注释结果等文件下载。
          </p>
          <img src={require("../../assets/img/图片44.png")} style={{width:size.width<=750?"90%":""}} alt="" loading='lazy'  />
          <p>图9 Download模块</p>
        </div>
      </div>
    </div>
  
    <div className="bottom_img">
      <div className="bottom_img_child">
        
        <p className="bottom_text">
          <span>参考文献:</span>
          <span>
            [1] Song JM, Liu DX, Xie WZ, et al. BnPIR:Brassica napus
            pan‐genome information resource for 1689 accessions. Plant
            Biotechnol J 2021; 19: 412-414.
          </span>
          <span>[2] http://wheat.cau.edu.cn/WheatUnion/</span>
        </p>
      </div>
    </div>
  </div>
  )
}

export default ExcavateIndex
