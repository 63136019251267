import React, { useEffect } from 'react'
import "./newscontent.model.css"
import { useParams, useSearchParams } from 'react-router-dom'
import NewsText from '../../components/NewsText'
function NewsContentIndex() {
    const [search,setSearch]=useSearchParams()
   
  return (
    <div className='news_content'>
       <NewsText id={search.get('name')}/>
    </div>
  )
}

export default NewsContentIndex
