import React from 'react'
import "./textindextwo.model.css"
function TextIndexTwo() {
  return (
    <div className='TextTwo_app'>
        <div className='text_two_child'>2</div>
    </div>
  )
}

export default TextIndexTwo
