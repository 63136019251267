const MfMgp = [
  {
    key: "1",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        href="#/MFMGP"
        target="_blank"
        rel="noopener noreferrer"
      >
        About the model
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        href="#/MFMGP"
        target="_blank"
        rel="noopener noreferrer"
      >
        How to use
      </a>
    ),
  },
  {
    key: "3",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        href="#/MFMGP"
        target="_blank"
        rel="noopener noreferrer"
      >
        About Us
      </a>
    ),
  },
  {
    key: "4",
    label: (
      <a
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        href="#/MFMGP"
        target="_blank"
        rel="noopener noreferrer"
      >
        Contact Us
      </a>
    ),
  },
];
export default MfMgp;
