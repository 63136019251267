import React, { useCallback, useEffect, useState } from "react";
import "./intelligent.model.css";
function IntelligentIndex() {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <div className="intelligent_app">
      <div className="intell_title">
        <h1>基因组选择育种</h1>
      </div>
      <div className="intell_easy">
        <div className="intell_child">
          <p style={{ width: "100%", height: "10%", textIndent: "3rem" }}>
            随着高通量测序的发展，全基因组选择（genomic selection,
            GS）技术逐渐推广并应用到动植物遗传改良中。该技术在2001年首次由Meuwissen等提出，结合个体间基因组遗传信息的差异，从表型。全基因组选择是指通过覆盖全基因组范围内的高密度标记进行育种值估计，从中选择高估值或低估值个体的育种选择方法。其主要方法是首先通过用参考群体中表型数据和全基因组大量的遗传标记估计出单个标记或染色体片段的效应值，然后再通过候选群体的全基因组的遗传标记去预测候选群体中每个个体基因组估计育种值（genomic
            estimated breeding value, GEBV）。相较常规育种，具有以下优势：
          </p>
          <p>
            1）能够在得到动植物个体DNA的时即对其进行育种值评估。可以缩短世代间隔，从而加快遗传进展并且降低经济投入。{" "}
          </p>
          <p>
            2）传统育种选择方法受限的性状或无法早期进行度量的性状或难操作成本高的性状上，如宰杀才能度量的性状，会更具优势。
          </p>
          <p>
            3）全基因组范围内的标记能够解释尽可能多的遗传变异，可以对遗传效应进行较为准确的检测和估计。
          </p>
          <p>
            4）能够较准确的评估遗传力较低、难测定的性状或测定费用较高的性状。
          </p>
          <p>
            5）通过基因组选择的方式，即使单个标记的效应很微小，导致遗传变异的所有遗传效应也都能够被SNP标记捕获，所以比传统的基于系谱和表型数据的最佳线性无偏模型得到更高的可靠性。
          </p>
          <p style={{ width: "100%", height: "10%", textIndent: "3rem" }}>GS模型的间接法是通过构建参考群，利用参考群个体的表型和全基因组的基因分型信息，估计全基因组中每一个 SNP在不同性状的效应值，再利用参考群 SNPs 的标记效应，计算候选群体GEBV。常用的估计方法包括岭回归最佳线性无偏预测法 (ridge regression best linear unbiased prediction, RRBLUP) 和贝叶斯法（BayesA、BayesB、BayesC等）。</p>
          <div className="easy_img_one">
            <img src={require('../../assets/img/图片54.png')} style={{width:size.width<=750?"90%":""}} alt="" loading="lazy" />
            <p>图 间接法GS思路</p>
          </div>
          <p style={{ width: "100%", height: "10%", textIndent: "3rem" }}>直接法是通过SNPs构建个体间关系矩阵，将关系矩阵放入混合模型方程组(mid model equations, MME)直接获得个体的基因组估计育种值。常用的估计方法包括利用标记构建个体间关系矩阵G的基因组最佳线性无偏估计法（GBLUP）、系谱矩阵A和基因组关系矩阵G构建为H矩阵的一步法(single step GBLUP, SSBLUP)。</p>
          <div className="easy_img_one">
            <img src={require('../../assets/img/图片55.png')} style={{width:size.width<=750?"90%":""}} alt="" loading="lazy" />
            <p>图 直接法GS思路</p>
          </div>
          <p style={{ width: "100%", height: "10%", textIndent: "3rem" }}>使用机器学习的算法，对梯度提升决策树算法进行大量优化后的lightgbm算法作为表型预测的内核，相比于GBLUP可以在不丢失精确度的情况下提升预测效率和支持非连续表型值预测。通过随机种n棵决策树，对每棵决策树直接选择最大收益的节点来展开，在更小计算代价上去选择我们需要的决策树，控制树的深度和每个叶子节点的数据量，能减少过拟合现象。</p>
          <div className="easy_img_one">
            <img src={require('../../assets/img/图片95.png')} style={{width:size.width<=750?"90%":""}} alt="" loading="lazy" />
            <p>图 算法的叶片增长图</p>
          </div>
          <div className="easy_img_one">
            <img src={require('../../assets/img/图片94.png')} style={{width:size.width<=750?"90%":""}} alt="" loading="lazy" />
            <p>图 算法的叶片增长图</p>
          </div>
          <p style={{width:"100%",height:"10%",display:"flex",alignItems:"center",fontSize:"1.25rem",margin:"0.625rem 0",paddingLeft:"1.25rem"}}>公司的预测模型准确度如图所示。</p>
          <div className="easy_img_one">
            <img src={require('../../assets/img/图片96.png')} style={{width:size.width<=750?"90%":""}} alt="" loading="lazy" />
            <p>图7 模型准确性图</p>
          </div>
          <p style={{ width: "100%", height: "10%", textIndent: "3rem" }}>通过DNN人工神经网络的策略构建GS模型，可以更好地处理基因组中的复杂交互作用和非线性关系，从而提供更准确和可靠的预测。具体原理步骤为：在特征图中不同的通道中构建CNN架构，并由多个卷积层和全连接层组成；使用Adam优化器（该优化器结合了动量和自适应学习率方法的原则）来更新模型的权重，并加速模型向最优拟合收敛；使用CA+基线网络结构来增强模型对特征矩阵内部的空间细节和通道间相关性的聚焦能力，最终构建深度学习模型。最终通过构建好的深度学习模型进行育种群体的育种值计算，结合最优亲本选配技术，从而达到品种改良的目的。</p>
          <div className="easy_img_one">
            <img src={require('../../assets/img/图片97.png')} style={{width:size.width<=750?"90%":""}} alt="" loading="lazy" />
            <p>图 深度学习育种技术概括图</p>
          </div>
          <p style={{ width: "100%", height: "10%", textIndent: "3rem" }}>参考群体中每个个体都有已知的表型和基因型，通过合适的统计模型可以估计出每个SNP或不同染色体片段的效应值（中间曼哈顿图）；然后对候选群体（candidate population）每个个体进行基因分型，利用参考群体中估计得到的SNP效应值来计算候选群体中每个个体的GEBV；最后根据GEBV排名（密度图和直方图）对个体进行选留，待选留个体（selected candidates）完成性能测定后，这些个体又可以被放入参考群体，用于重新估计SNP的效应值，如此反复。如果没有候选群体，我们可以从目前已有的参考群体缩小育种个体范围，从中选留更好的个体做为下一代参考群体。</p>
          <div className="easy_img_one">
            <img src={require('../../assets/img/图片59.png')} style={{width:size.width<=750?"90%":""}} alt="" loading="lazy" />
            <p>图 育种样本GEBV估计和选择育种样本</p>
          </div>
          <p>参考文献：Zhu W, Han R, Shang X, et al. The CropGPT project: A call for a global, coordinated effort in precision design breeding driven by AI using biological big-data. Mol Plant. Published online December 22, 2023. doi:10.1016/j.molp.2023.12.015</p>
        </div>
      </div>
      <div className="breed_title">
        <h1>智能化精准育种平台</h1>
      </div>
      <div className="breed_content">
        <div className="breed_child">
          <p className="itemP_one">赵书红老师团队创建了完整的三元商品猪基因组精准选配新算法、新软件和操作流程。团队首次利用基因组精准选配技术开展杜长大三元商品猪生产，并且提出了一种快速估计标记效应值的新方法。当前，杜洛克、长白、大白三系配套杂交是商品猪生产的主流模式，满足了我国90%的猪肉供应，主要是通过品种间配合力测定来确定最佳品种组合，从而达到提高三元商品猪生产性能的目的。然而，配套系培育也有其不足，整个过程复杂，周期长、成本高，且无法保证每个公母猪配对的杂种优势最大化。随着高通量测序技术发展，基因组信息开始用于选配，能够将传统配套系的“品种水平”群体选配升级为“个体水平”精准选配。基因组精准选配流程包括：首先，利用三元商品猪参考群体估计标记效应值；然后，根据待配公母猪的基因组信息计算所有交配组合后代的基因型概率；最后，整合标记效应和基因型信息计算三元商品猪后代的期望遗传值，根据后代期望遗传值最大化原则推荐杜洛克公猪和长大二元母猪最佳配对组合。</p>
          <p className="itemP_two">三元商品猪参考群体的基因型数据和表型数据用于计算标记效应值，杜洛克公猪和长大二元母猪的基因型数据用来计算所有交配组合后代的基因型概率，根据标记效应值与基因型信息计算交配组合后代的期望遗传值，根据期望遗传值最大化原则推荐杜洛克公猪和长大二元母猪最佳配对组合。团队使用875头纯种杜洛克公猪、350头长大二元母猪和3,573头杜长大三元商品猪的基因型和表型数据进行基因组选配分析，系统评估了基因组精准选配所能带来的效益；与随机交配相比，基因组精准选配后代料重比下降0.12；30kg~120kg测定日龄缩短4.64天；眼肌面积增加2.65cm²。因此，联合应用基因组选择和基因组精准选配技术，能够最大限度利用优良基因效应来提升养殖效益，该模式尤其适用于具有高端核心群和大规模公猪站的龙头企业。另外，基因组精准选配算法具有通用性，不仅可以用于猪，也可以用于牛、羊、禽等物种的精准选配分析。</p>
          <img src={require('../../assets/img/图片75.png')} style={{width:size.width<=750?"90%":"50%"}} alt="" loading="lazy" />
          <p className="img_p">图 基因组精准选配流程</p>
          <p className="references_p">参考文献：Tang Z, Yin L, Yin D, et al. Development and application of an efficient genomic mating method to maximize the production performances of three-way crossbred pigs. Brief Bioinform. 2023;24(1):bbac587. doi:10.1093/bib/bbac587</p>
          <p className="itemP_one">赵汀老师团队通过全基因组关联分析（GWAS）鉴定了117个棉花产量和品质的功能遗传位点，然而这些遗传位点所调控的基因并不清楚。通过对279份陆地棉核心种质开花后1天的胚珠进行了转录组测序，共计鉴定了12,207 eQTLs。与GWAS的共定位分析，发现了了38个与性状关联的基因调控网络GRNs，并发现了有34个eQTL基因同时属于不同性状的GRNs。同一GRN的基因在不同组织中还具有相似的表达模式。利用GRN基因的遗传变异计算遗传力可以获得比GWAS位点更高的解释率。最后,还采用了XGBoost机器学习算法和转录组关联分析（Transcriptome-wide association studies，TWAS）分析方法，对基因的重要性进行了排序，并对排序靠前的基因进行了功能验证。这项工作对遗传位点的一因多效提供了新的见解，并利用了eQTL和机器学习方法来挖掘功能基因。</p>
          <p className="references_p">
          参考文献：Zhao T, Wu H, Wang X, et al. Integration of eQTL and machine learning to dissect causal genes with pleiotropic effects in genetic regulation networks of seed cotton yield. Cell Rep. 2023;42(9):113111. doi:10.1016/j.celrep.2023.113111
          </p>
          <p className="itemP_one">张献龙院士团队利用纤维品质相关的调控变异和候选基因对3,552份陆地棉种质资源进行育种评价，并训练模型开展纤维品质预测。研究发现，同时提供候选基因的基因型与同源基因的表达量可以显著提升纤维品质预测精度。通过基因型和表达量预测376份陆地棉材料两两杂交后有利变异的积累情况，并对杂交后代的纤维品质进行预测。不同材料之间可以提供互补的有利变异，这些变异分布在8个调控模块，使优异基因型得到有效互补聚合。通过计算，预期用不同亲本进行杂交改良，可以使纤维长度由28.77 mm提升至31.04 mm，纤维强度由29.72 cN/tex提升至37.66 cN/tex。这些结果为育种家精准选择亲本组合改良纤维品质提供了指导。在今后育种中，可以根据目标性状选择合适的模块，并通过对目标位点的聚合实现模块中更多优异基因的整合利用，即实现模块化的育种。</p>
          <img src={require('../../assets/img/图片76.png')} style={{width:size.width<=750?"90%":"70%"}} alt="" loading="lazy" />
          <p className="img_p">图 棉花纤维基因组设计育种</p>
          <p className="references_p">参考文献：You J, Liu Z, Qi Z, et al. Regulatory controls of duplicated gene expression during fiber development in allotetraploid cotton. Nat Genet. 2023;55(11):1987-1997. doi:10.1038/s41588-023-01530-8</p>
          <p className="itemP_one">
          动植物新品种选育方法通常有驯化育种、杂交育种和分子育种等。如今，随着动植物重要性状的表型采集通量和精确度越来越高，以及各种重要性状相关的基因分子功能研究的突破，使动植物新品种的精准选育进入“个体水平”。融合大数据和更精准的智能技术可以把多种优异的基因快速聚合起来，智能选择目标功能性状基因的亲本进行组配，加速良种培育进程，最终实现自主培育的良种供给到世界各地。
          </p>
          <p className="itemP_two">
          智能化精准育种平台可以方便不同领域的科研和育种人员，在可视化的操作界面上充分利用已有的表型和基因型数据来构建重要性状的基因组预测模型。该平台可以获得基因组关联分析信号，同时提供适合不同物种的传统模型、机器学习和深度学习模型用于基因组预测和亲本精准选配。也可以只利用基因型数据，对不同物种进行亲缘关系最优组合选配。
          </p>
          <img src={require('../../assets/img/图片77.png')} style={{width:size.width<=750?"90%":"50%"}} alt="" loading="lazy" />
          <p className="img_p">图 百奥华兴智能化精准育种平台</p>
         
        </div>
      </div>
    </div>
  );
}

export default IntelligentIndex;
