import React from 'react'
import "./tech.model.css"
function TechnologyIndex() {
  return (
    <div className='Technology_app'>
      <div className='tech_title'>qwe</div>
    </div>
  )
}

export default TechnologyIndex
