import React, { useCallback, useEffect, useState } from "react";
import "./mole.model.css";
function MolecularIndex() {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <div className="mole_app">
      <div className="mole_title">
        <h1>分子身份证开发</h1>
      </div>
      <div className="mole_context">
        <p>
          分子身份证位点确定工作主要依赖于表型鉴定，但是随着杂交育种的推广，经过几代杂交后的群体的个体表型与亲本高度相似，因此，单纯利用表型性状进行位点确定不够准确、全面和科学。随后，位点确定工作由传统的表型鉴定发展为DNA分子标记技术，DNA分子标记的应用能够为位点确定提供准确、快速的渠道。早期的DNA分子标记技术使用微卫星、ALFP等标记进行位点确定。随着基因芯片技术和测序技术的飞速发展，SNP位点逐渐被用于位点确定。SNP（Single
          nucleotide
          polymorphism）是指由单核苷酸变异引起的DNA序列多态性，包括单碱基转换、颠换、插入和缺失等形式。相比于其他DNA分子标记，SNP标记技术以其高通量、高集成、微型化和自动化等优点被广泛应用于位点确定。SNP具有以下特点：（1）SNP数目多，密度高，分布广泛。在人类基因组中，平均每1Kb即存在1个SNP位点；（2）富有代表性。部分位于基因编码区的SNP有可能改变基因功能或影响基因表达，从而影响个体性状，为性状遗传研究提供一定的理论基础；（3）具有遗传稳定性。SNP的基因突变概率小，尤其编码区的SNP高度稳定，遗传分析的重复性高；（4）SNP的分型易于实现自动化。基于以上优点，SNP成为位点确定新一代的理想标记。
        </p>
        <p>
          为了在成千上万的SNP位点中识别出最具代表性的遗传标记，一些学者利用不同的统计方法结合遗传信息对SNP位点进行筛选。Pfaff等人利用δ方法，以两个物种间的等位基因频率绝对差为判别标准进行分类，Weir等人利用
          Wright’s
          Fst方法，依赖于预先定义的两个物种间的等位基因频率的差异最大化进行判别。但是δ和Wright’s
          Fst只可以用于两个种群的判别，并且没有清晰的统计特性定义。为解决两个品种以上的判别，Rosenberg等人提出了一种相关性衡量的方法，使用互信息（In）描述相关性，以此来表示不同品种的FST之间的关系。然而，上述的传统方法都没有考虑到SNP数据的高维小样本的特点，且都需要计算等位基因频率，大大增加了计算复杂度和运算量。为此，Paschou等人提出利用PCA提取SNP位点的方法。
        </p>
        <p>
          PCA虽然可以有效降低样本维数，但是对于品种识别来说SNP位点个数还是过多，且PCA对非线性数据的分类效果并不好，也无法评估所选SNP位点的重要程度。因此，刘月丽等人提出了一种新的SNP位点筛选方法，将PCA和随机森林相结合提取高信息量的SNP位点，并且成功地运用该方法进行羊的位点确定。随机森林有以下优点，可以利用数据之间相互依赖的信息，构建多元分类规则；在每个类别边界上有很好的非线性；提供了变量重要性的衡量方法。运用PCA与随机森林结合的方法，能够实现利用少量高信息量的SNP进行位点确定，提高了鉴定的准确率，降低了位点确定的成本。
        </p>
        <p>
          在位点确定的研究中，之前对于SNP标记的获取大部分依赖于基因芯片，然而，随着测序技术的发展，通过重测序的方法获得SNP变得常见。与基因芯片相比，重测序的方法弥补了针对特定群体适用性的问题，不仅能够得到足量的SNP标记，还能得到基因组变异的信息，有进一步研究的价值。综上所述，本项目采用重测序的方法获取SNP，基于PCA与随机森林相结合的方法筛选SNP，最终实现高准确率的位点确定并进行分子生份证的开发。
        </p>
      </div>
      <div className="card_val_app">
        <div className="card_val_app_child">
          <p className="card_title">DNA 分子身份证的价值与应用</p>
          <p className="card_context">品种资源的分子身份证与DNA指纹图谱的功能相同，都是为了区分不同生物个体或品种。相对于指纹图谱，分子身份证是将品种资源的基本信息用特定的数字编码为数字串，并且辅以条形码、二维码的形式展现，更加简明直观地区分品种资源之间的差异。分子身份证因其自身所具有特性，可利用机器进行扫描，达到方便快捷地识别大量品种资源，提高品种资源鉴定和评价的效率。另外，分子身份证的唯一性，也可有效甄别市场上同名异物、同物异名现象，有利于品种识别与保护。许多物种已利用分子标记技术构建分子身份证，如水稻、百合、桃、枸杞和茶树等。利用SNP标记技术将DNA指纹图谱与品种资源基本信息相结合，可为每份品种资源构建独有的分子身份证。该身份证信息包括了品种资源的分子指纹码和属性码，可快速了解其分子信息、来源等基本信息。研究结果对于品种资源区分和精准鉴定、分子数据数字化建立具有重要的意义和实际应用价值，为品种资源DNA分子身份证的构建提供了思路。DNA分子身份证一般由24~50位数字组成，前4位数字是品种的基本信息，后20~46位是基于位点确定筛选出的SNP位点信息。以茶树分子身份证为例，该分子身份证是由28位数字组成，其中前4位数字是茶树的基本信息，后24位是茶树的位点信息。如云霄云香茶，它属于茶组植物中的茶树，可编码为1；原产地为福建，编码为35；茶树种质资源类型为地方种质，编码为2，因此基本信息编码为1352。共有24种基因型，3种等位基因（XY、XX和YY），每个位点分别用1~3代表等位基因的多态性。以云霄云香茶为例，其身份证信息及其转化的条码和二维码见下图。</p>
          <div className="img_p">
            <img src={require('../../assets/img/图片36.png')} style={{width:size.width<=750?"100%":""}} alt="" loading="lazy" />
            <p>图 DNA分子身份证示例</p>
          </div>
          <p style={{width:"100%",height:"20%",display:"flex",alignItems:"center",margin:"0.625rem 0"}}>参考文献：樊晓静, et al., 利用SNP标记构建茶树品种资源分子身份证. 中国农业科学, 2021. 54(08): p. 1751-1772.</p>
        </div>
      </div>
    </div>
  );
}

export default MolecularIndex;
